import gql from 'graphql-tag'

export const getUsers = gql`
    query getUsers {
        getUsers{
            users {
                id
                email
                createdAt
                lastLoggedIn
                admin
                company {
                    name
                }
            }
            status {
                success
                errorMessage
            }
        }
    }
`

export const getTests = gql `
    query getTests{
        tests{
            id
            company{
                name
                archetype
            }
            description
            createdAt
            lastUpdatedAt
            endDate
            hasEnded
            screenOutActive
            rewardActive
        }
    }
`

export const getOpenTests = gql `
    query openTests{
        openTests{
            id
            company{
                name
            }
            title
            companyTitleSlug
            description
            createdAt
            lastUpdatedAt
            endDate
            screenOutActive
            rewardActive
            respondentsCount
        }
    }
`

export const getClosedTests = gql `
    query closedTests{
        closedTests{
            id
            company{
                name
            }
            title
            companyTitleSlug
            description
            createdAt
            lastUpdatedAt
            endDate
            screenOutActive
            rewardActive
            respondentsCount
        }
    }
`

export const getCompanyData = gql `
    query getCompanies{
        getCompanies {
            companies {
                name
                archetype
                users {
                    id
                }
                tests {
                    title
                }
            }
            status {
                success
                errorMessage
            }
        }
    }
`

export const getCompanyNames = gql `
    query getCompanies{
        getCompanies {
            companies{
                name
            }
            status {
                success
                errorMessage
            }
        }
    }
`

export const getTestBySlug = gql `
    query getTestBySlug ($input: SlugInput!){
        getTestBySlug(input: $input){
            test{
                id
                company{
                    name
                }
                hasEnded
                screenOutActive
                rewardActive
                demoVideoLink
                logoLocation
                screenOutQuestions{
                    id
                    questionValue
                    possibleAnswers
                    correctAnswers
                    questionType
                }
                questionSet{
                    name
                    ratingQuestions {
                        id
                        archetype {
                            imageLocation
                            archetype
                            displayFirstName
                            displayLastName
                            description
                        }
                    }
                    questions {
                        id
                        leftArchetype {
                            imageLocation
                            archetype
                            displayFirstName
                            displayLastName
                            description
                        }
                        rightArchetype {
                            imageLocation
                            archetype
                            displayFirstName
                            displayLastName
                            description
                        }
                    }
                }
            }
            status{
                success
                errorMessage
            }
        }
    }
`

export const getTestResults = gql `
    query getTestResults($testId: ID!){
        getTestResults(testId: $testId) {
            test {
                id
                questionSet {
                    id
                    name
                    questions {
                        id
                        leftArchetype {
                            archetype
                        }
                        rightArchetype {
                            archetype
                        }
                    }
                    ratingQuestions {
                        archetype {
                            archetype
                        }
                    }
                }
                respondents {
                    id
                    suitable
                    answers {
                        question {
                            id
                        }
                        ratingQuestion {
                            id
                            archetype {
                                archetype
                            }
                        }
                        archetype
                        points
                    }
                    screenOutAnswers {
                        answer
                        screenOutQuestion {
                            questionValue
                        }
                    }
                }
            },
            status{
                success
                errorMessage
            }
        }
    }
`

export const getRewardEmail = gql`
    query getRandomRewardEmail($testId: ID!){
        getRandomRewardEmail(testId: $testId){
            email
            status {
                success
                errorMessage
            }
        }
    }
`
