import ApolloClient from '@/js/graphql/ApolloClient';
import { addTest, openTest, stopTest } from '@/js/graphql/mutations';
import { getClosedTests, getOpenTests } from '@/js/graphql/queries';

class TestService {
    static async addTest(test) {
        if ((test.companyName === '' || null) ||
            (test.title === '' || null) ||
            (test.description === '' || null) ||
            (test.introductionVideoLink === '' || null)) {
            return false;
        }

        const response = await ApolloClient.defaultClient.mutate({
            mutation: addTest,
            variables: {
                'input': {
                    'companyName': test.companyName,
                    'title': test.title,
                    'description': test.description,
                    'demoVideoLink': test.introductionVideoLink,
                    'endDate': null,
                    'screenOutActive': test.screenOutActive,
                    'rewardActive': test.rewardActive
                },
            },
        });

        return response.data;
    }

    static async stopTest(id) {
        const response = await ApolloClient.defaultClient.mutate({
            mutation: stopTest,
            variables: {
                'id': id,
            },
        });

        return response.data;
    }

    static async openTest(id) {
        const response = await ApolloClient.defaultClient.mutate({
            mutation: openTest,
            variables: {
                'id': id,
            },
        });

        return response.data;
    }

    static async loadOpenTests() {
        const response = await ApolloClient.defaultClient.query({
            query: getOpenTests,
        });

        const tests = [];
        response.data.openTests.forEach(test => {
            tests.push(test);
        });

        return tests;
    }

    static async loadClosedTests() {
        const response = await ApolloClient.defaultClient.query({
            query: getClosedTests,
        });


        const tests = [];
        response.data.closedTests.forEach(test => tests.push(test));

        return tests;
    }
}

export default TestService;
