import ApolloClient from '@/js/graphql/UnsecureApolloClient';
import { addAnswers } from '@/js/graphql/mutations';

class AnswerService {
    static async addAnswers(answers, testId) {
        const response = await ApolloClient.defaultClient.mutate({
            mutation: addAnswers,
            variables: {
                'testId': testId,
                'input': answers
            },
        });

        return response.data.addAnswers;
    }
}

export default AnswerService;
