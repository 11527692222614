<template>
    <div class="screenout-div" v-if="screenOutQuestion.questionType === 'YESNO'">
        {{ screenOutQuestion.questionValue }}
        <div class="yesnobuttons-div">
            <span><input type="radio" v-model="yesNoAnswer" value="Yes">Ja</span>
            <span><input type="radio" v-model="yesNoAnswer" value="No">Nee</span>
        </div>
    </div>
    <div class="screenout-div" v-else-if="screenOutQuestion.questionType === 'NUMERICRANGE'">
        {{ screenOutQuestion.questionValue }}
        <input class="numericrange-input" type="number" v-model="numericRangeAnswer">
    </div>
    <div class="screenout-div" v-else-if="screenOutQuestion.questionType === 'DROPDOWN'">
        {{ screenOutQuestion.questionValue }}
        <select class="dropdown-select" v-model="dropdownAnswer">
            <option v-for="possibleAnswer in screenOutQuestion.possibleAnswers">
                {{ possibleAnswer }}
            </option>
        </select>
    </div>
    <div v-else>
    </div>
</template>

<script>
export default {
    name: 'ScreenOutComponent',
    props: {
        screenOutQuestion: {},
    },
    data() {
        return {
            yesNoAnswer: '',
            numericRangeAnswer: '',
            dropdownAnswer: '',
        };
    },
    methods: {
        checkIfAnswerIsNull() {
            switch (this.screenOutQuestion.questionType) {
                case 'YESNO':
                    return this.yesNoAnswer === null || this.yesNoAnswer === '';
                case 'NUMERICRANGE':
                    return this.numericRangeAnswer === null || this.numericRangeAnswer === '';
                case 'DROPDOWN':
                    return this.dropdownAnswer === null || this.dropdownAnswer === '';
            }
        },
        getScreenOutAnswer() {
            let answer;

            switch (this.screenOutQuestion.questionType) {
                case 'YESNO':
                    answer = this.yesNoAnswer;
                    break;
                case 'NUMERICRANGE':
                    answer = this.numericRangeAnswer.toString();
                    break;
                case 'DROPDOWN':
                    answer = this.dropdownAnswer;
                    break;
            }
            return {'answer': answer, 'screenOutQuestionId': this.screenOutQuestion.id};
        },
        validateAnswer() {
            switch (this.screenOutQuestion.questionType) {
                case 'YESNO':
                    return this.yesNoAnswer === this.screenOutQuestion.correctAnswers[0];
                case 'NUMERICRANGE':
                    let minimalNumber = Math.min(this.screenOutQuestion.correctAnswers[0], this.screenOutQuestion.correctAnswers[1]);
                    let maximumNumber = Math.max(this.screenOutQuestion.correctAnswers[0], this.screenOutQuestion.correctAnswers[1]);
                    return this.numericRangeAnswer >= minimalNumber && this.numericRangeAnswer <= maximumNumber;
                case 'DROPDOWN':
                    return this.screenOutQuestion.correctAnswers.includes(this.dropdownAnswer);
            }
        },

    },
};
</script>

<style scoped lang="scss">
@import "@/styles/app.scss";

//To remove arrows from the number input on chrome
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

//To remove arrows from the number input on firefox
input[type=number] {
  -moz-appearance: textfield;
}

.screenout-div {
  display: flex;
  flex-direction: column;

  .yesnobuttons-div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%
  }

  .yesno-select {
    height: 2.5rem;
    width: 100%;
    background-color: $colorPink;
    border: 1px solid $colorWhite;
    border-radius: 10px;
    padding-left: 1rem;
    color: $colorWhite;
    font-size: 16px;
    line-height: 32px;
    font-weight: 500;

    &:focus {
      outline: none;
    }
  }

  .numericrange-input {
    height: 2.5rem;
    width: 93%;
    background-color: $colorPink;
    border: 1px solid $colorWhite;
    border-radius: 10px;
    padding-left: 1rem;
    color: $colorWhite;
    font-size: 16px;
    line-height: 32px;
    font-weight: 500;

    &:focus {
      outline: none;
    }
  }

  .dropdown-select {
    height: 2.5rem;
    width: 100%;
    background-color: $colorPink;
    border: 1px solid $colorWhite;
    border-radius: 10px;
    padding-left: 1rem;
    color: $colorWhite;
    font-size: 16px;
    line-height: 32px;
    font-weight: 500;

    &:focus {
      outline: none;
    }
  }
}
</style>
