import ApolloClient from '@/js/graphql/UnsecureApolloClient';
import { getTestBySlug } from '@/js/graphql/queries';
import { sendRewardEmail } from '@/js/graphql/mutations';

class RewardEmailService {
    static async sendRewardEmail(email, testId) {
        const response = await ApolloClient.defaultClient.mutate({
            mutation: sendRewardEmail,
            variables: {
                'input': {
                    'testId': testId,
                    'email': email,
                },
            },
        });

        return response.data.sendRewardEmail;
    }
}

export default RewardEmailService;
