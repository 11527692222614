<template>
    <div class='main-div'>
        <button class='modal-button-open' v-on:click='openModal'>{{
                $t('CreateAccountModal.OpenModal')
            }}
        </button>
        <div class='modal-div' v-if='modalShowing' v-on:click='closeModal'>
            <div class='modalcontent-div' @click.stop=''>
                <span class="modal-title">{{ $t('CreateAccountModal.ModalTitle') }}</span>
                <span class="modal-description">{{ $t('CreateAccountModal.ModalDescription') }}</span>
                <FormKit
                    type='form'
                    :submit-label="$t('CreateAccountModal.CreateNewAccountButton')"
                    @submit='createAccount'
                    :submit-attrs="{
                        inputClass: 'formkit-input',
                    }"
                >
                    <FormKit
                        type='text'
                        :label="$t('CreateAccountModal.EmailLabel')"
                        :placeholder="$t('CreateAccountModal.EmailPlaceholder')"
                        v-model='account.email'
                        validation='required'
                    />
                    <FormKit
                        type='password'
                        :label="$t('CreateAccountModal.PasswordLabel')"
                        :placeholder="$t('CreateAccountModal.PasswordPlaceholder')"
                        v-model='account.password'
                        validation='required'
                    />
                    <FormKit
                        type='password'
                        :label="$t('CreateAccountModal.PasswordCheckLabel')"
                        :placeholder="$t('CreateAccountModal.PasswordCheckPlaceholder')"
                        v-model='passwordCheck'
                        validation='required'
                    />
                    <FormKit
                        type='select'
                        :label="$t('CreateAccountModal.CompanyNameLabel')"
                        :placeholder="$t('CreateAccountModal.CompanyNamePlaceholder')"
                        :options='companies'
                        v-model='account.companyName'
                        validation='required'
                    />
                    <div class="checkbox-div">
                        <input type="checkbox" class="admincheckbox-input" v-model="account.admin">
                        <span class="admincheckbox-label">{{ $t('CreateAccountModal.Admin') }}</span>
                    </div>
                    <span class="modal-errormessage">{{ errorMessage }}</span>
                </FormKit>
                <div class="button-div">
                    <button v-on:click="closeModal" class="cancel-button">
                        {{ $t('CreateCompanyModal.CancelCreateCompanyButton') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CompanyService from '@/js/services/secureServices/CompanyService';
import AccountService from '@/js/services/secureServices/AccountService';

export default {
    name: 'CreateAccountModal',
    components: {},
    data() {
        return {
            modalShowing: false,
            companies: [],
            account: {
                email: '',
                password: '',
                companyName: '',
                admin: false,
            },
            passwordCheck: '',
            errorMessage: '',
        };
    },
    async created() {
        this.companies = await CompanyService.getCompanyNames();
    },
    methods: {
        openModal() {
            this.modalShowing = true;
        },
        closeModal() {
            this.modalShowing = false;
        },
        async createAccount() {
            this.errorMessage = '';
            if (this.validateEmail(this.account.email)){
                if (this.account.password === this.passwordCheck) {
                    let result = await AccountService.createAccount(this.account);

                    if (result.createUser.status.success) {
                        this.$emit('addAccountToList', result.createUser.user);
                        this.closeModal();
                        this.account.email = '';
                        this.account.password = '';
                        this.passwordCheck = '';
                        this.account.companyName = '';
                        this.account.admin = false;
                    } else {
                        this.errorMessage = this.$t('CreateUserModal.ErrorMessage');
                        console.error(result.createUser.status.errorMessage);
                    }
                } else {
                    this.errorMessage = this.$t('CreateUserModal.NoPasswordMatch');
                }
            } else {
                this.errorMessage = this.$t('CreateUserModal.NoValidEmail');
            }
        },
        validateEmail(email) {
            return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
        },
    },
};
</script>

<style scoped lang='scss'>
@import '@/styles/app.scss';

.main-div {

  .modal-button-open {
    @extend .button-gray;
    width: 208px;
    height: 64px;
    margin: 24px 48px 19px 0;
    float: right;
  }

  .modal-div {
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .modalcontent-div {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      box-shadow: 0 0 0 50vmax rgba(0, 0, 0, .5);
      max-height: 75%;
      z-index: 1;
      background-color: $colorWhite;
      margin: auto;
      padding: 60px 75px;
      width: 550px;

      .checkbox-div {
        display: flex;
        flex-direction: row;

        .admincheckbox-input {
          @extend .button-black;
          width: 32px;
          height: 32px;
          accent-color: $colorBlack;

          &:hover {
            accent-color: $colorHoverGray;
          }
        }

        .admincheckbox-label {
          @extend .modal-inputlabel-font;
          margin-left: 10px;
        }
      }

      .modal-errormessage {
        @extend .modal-errormessage-font;
      }

      .button-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .cancel-button {
          @extend .modalcancel-button;
          @extend .modal-cancelbutton-font;
          height: 32px;
          width: 200px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
