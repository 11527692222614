<template>
    <div class='main-div'>
        <button class='modal-button-open' v-on:click='openModal'>{{
                $t('CreateTestModal.CreateNewTestButton')
            }}
        </button>
        <div class='modal-div' v-if='modalShowing' v-on:click='closeModal'>
            <div class='modalcontent-div' @click.stop=''>
                <FormKit
                    type='form'
                    :submit-label="$t('CreateTestModal.CreateNewTestButton')"
                    @submit='createTest'
                    :submit-attrs="{
                        name: 'addTestButton',
                        inputClass: 'formkit-input',
                    }"
                >
                    <div class='formrow-div'>
                        <div class='formcolumn-div'>
                            <span class="modal-title">{{ $t('CreateTestModal.ModalTestTitle') }}</span>
                            <span class="modal-description">{{ $t('CreateTestModal.ModalTestDescription') }}</span>
                            <FormKit
                                type='select'
                                name='addTestCompany'
                                :label="$t('CreateTestModal.CompanyNameLabel')"
                                :placeholder="$t('CreateTestModal.CompanyNamePlaceholder')"
                                :options='companies'
                                v-model='test.companyName'
                                validation='required'
                            />
                            <FormKit
                                type='text'
                                name='addTestTitle'
                                :label="$t('CreateTestModal.TitleLabel')"
                                :placeholder="$t('CreateTestModal.TitlePlaceholder')"
                                v-model='test.title'
                                validation='required'
                            />
                            <FormKit
                                type='text'
                                name='addTestDescription'
                                :label="$t('CreateTestModal.DescriptionLabel')"
                                :placeholder="$t('CreateTestModal.DescriptionPlaceholder')"
                                v-model='test.description'
                                validation='required'
                            />
                            <FormKit
                                type='text'
                                name='addTestIntroductionVideo'
                                :label="$t('CreateTestModal.IntroductionVideoLinkLabel')"
                                :placeholder="$t('CreateTestModal.IntroductionVideoLinkPlaceholder')"
                                v-model='test.introductionVideoLink'
                                validation='required'
                            />
                            <div class="checkbox-div">
                                <input type="checkbox" class="rewardactivecheckbox-input" v-model="test.rewardActive">
                                <span class="rewardactivecheckbox-label"> {{ $t('CreateTestModal.RewardActiveLabel') }} </span>
                            </div>
                        </div>
                        <div class='formcolumn-div'>
                            <span class="modal-title">{{ $t('CreateTestModal.ModalScreenOutTitle') }}</span>
                            <span class="modal-description">{{ $t('CreateTestModal.ModalScreenOutDescription') }}</span>
                            <div v-for="(screenOutQuestion, index) in screenOutQuestions" ref="screenOutBlocks">
                                <ScreenOutQuestion :screenOutQuestion="screenOutQuestion" :index="index" @removeThisScreenOutQuestion="removeScreenOutQuestion"/>
                            </div>
                            <button class="addrespondentquestion-button" v-on:click='addOneScreenOutQuestion' v-show="screenOutQuestions.length <= 4">
                                <img class="addrespondentquestion-img" src="@/images/icons/PlusIcon.png" alt="+">
                                <span class="addrespondentquestion-span">{{ $t('CreateTestModal.AddRespondentQuestion') }}</span>
                            </button>
                        </div>
                    </div>
                </FormKit>
                <div class="button-div">
                    <button v-on:click="closeModal" class="cancel-button">
                        {{ $t('CreateCompanyModal.CancelCreateCompanyButton') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TestService from '@/js/services/secureServices/TestService';
import CompanyService from '@/js/services/secureServices/CompanyService';
import ScreenOutQuestion from '@/js/components/elements/ScreenOutQuestion.vue';
import ScreenOutQuestionService from '@/js/services/secureServices/ScreenOutQuestionService';

export default {
    name: 'CreateTestModal',
    components: {
        ScreenOutQuestion,
        ScreenoutQuestionService: ScreenOutQuestionService,
    },
    data() {
        return {
            modalShowing: false,
            dateValidation: '',
            test: {
                companyName: '',
                title: '',
                description: '',
                introductionVideoLink: '',
                screenOutActive: false,
                rewardActive: false,
            },
            companies: [],
            screenOutQuestions: [],
        };
    },
    async created() {
        this.companies = await CompanyService.getCompanyNames();

        const date = new Date();
        this.dateValidation = 'date|date_after:' + date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    },
    methods: {
        openModal() {
            this.modalShowing = true;
        },
        closeModal() {
            this.modalShowing = false;
        },
        addOneScreenOutQuestion() {
            if (this.screenOutQuestions.length >= 5) {
                return;
            }
            let index = this.screenOutQuestions.length;
            this.screenOutQuestions.push({
                //General
                index: index,
                selectedOption: '',
                questionValue: '',
                //Numerieke Schaal
                numeriekeRangeCorrectMinValue: '',
                numeriekeRangeCorrectMaxValue: '',
                //Ja/Nee
                yesNoCorrectAnswer: '',
                //Dropdown menu
                dropdownItems: [
                    {
                        textValue: '',
                        validAnswer: false,
                    },
                ],
            });

            this.$nextTick(() => {
                const blockIndex = this.screenOutQuestions.length - 1;
                const blocks = this.$refs.screenOutBlocks;
                const newBlock = blocks[blockIndex];
                newBlock.scrollIntoView({ behavior: 'smooth', block: 'start' });
            });
        },
        removeScreenOutQuestion(index) {
            this.screenOutQuestions.splice(index, 1);
        },
        async createTest() {
            if (this.screenOutQuestions.length >= 1) {
                this.test.screenOutActive = true;
            }
            const data = await TestService.addTest(this.test);

            if (data.createTest.status.success) {
                this.$emit('addTestToLists', data.createTest.test);

                if (this.screenOutQuestions.length >= 1) {
                    let screenOutData = await ScreenOutQuestionService.addScreenOutQuestions(this.screenOutQuestions, data.createTest.test.id);
                    if (!screenOutData.addScreenOutQuestions.status.success) {
                        console.error(screenOutData.addScreenOutQuestions.status.errorMessage);
                    }
                }

                this.closeModal();
                this.test = {
                    companyName: '',
                    title: '',
                    description: '',
                    introductionVideoLink: '',
                    screenOutActive: false,
                };
                this.screenOutQuestions = [];
            } else {
                console.error(data.createTest.status.errorMessage);
                alert(data.createTest.status.errorMessage);
            }
        },
    },
};
</script>

<style scoped lang='scss'>
@import '@/styles/app.scss';

.main-div {

  .modal-button-open {
    @extend .button-gray;
    width: 208px;
    height: 64px;
    margin: 24px 48px 19px 0;
    float: right;
  }

  .modal-div {
    position: fixed;
    z-index: 1;
    padding-top: 50px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .modalcontent-div {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      box-shadow: 0 0 0 50vmax rgba(0, 0, 0, .5);
      max-height: 80%;
      z-index: 1;
      background-color: $colorWhite;
      margin: auto;
      padding: 60px 75px;
      width: 1100px;

      .formrow-div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-height: 545px;
        width: 100%;

        .formcolumn-div {
          display: flex;
          flex-direction: column;
          padding: 20px;
          width: 50%;
          overflow-y: auto;

          &:first-child {
            border-right: 1px solid $colorAntGray;
          }

          .checkbox-div {
            display: flex;
            flex-direction: row;

            .rewardactivecheckbox-input {
              @extend .button-gray;
              width: 32px;
              height: 32px;
              accent-color: $colorBlack;

              &:hover {
                accent-color: $colorHoverGray;
              }
            }

            .rewardactivecheckbox-label {
              @extend .modal-inputlabel-font;
              margin-left: 10px;
            }
          }

          .addrespondentquestion-button {
            display: flex;
            align-content: flex-start;
            align-items: center;
            background-color: $colorBrokenWhite;
            box-shadow: 3px 3px 5px -2px rgba(0, 0, 0, 0.11);
            border: none;
            min-height: 68px;
            width: 100%;
            padding-left: 26px;

            &:hover {
              background-color: $colorMistyGray;
              cursor: pointer;
            }

            .addrespondentquestion-span {
              margin-left: 20px;
            }
          }
        }
      }


      .button-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .cancel-button {
          @extend .modalcancel-button;
          @extend .modal-cancelbutton-font;
          height: 32px;
          width: 200px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
