<template>
  <div class="main-div-transparant" v-if="test === null || test.hasEnded">
    <div class="title-div-small">
      {{ $t('RespondentView.NoTest') }}
    </div>
  </div>
  <div class="main-div-transparant" v-else>
    <div class="main-div-transparant" v-if="testStep === 1">
      <div class="content-div">
        <WelcomeComponent @nextPage="increaseStep" :test="test"/>
      </div>
      <div class="logo-div">
        <img class="logo" src="@/images/logo/motus.png" alt="MOTUS">
      </div>
    </div>
    <div class="main-div-transparant" v-if="testStep === 2">
      <div class="content-div">
        <IntroductionComponent @nextPage="increaseStep" :test="test"/>
      </div>
      <div class="logo-div">
        <img class="logo" src="@/images/logo/motus.png" alt="MOTUS">
      </div>
    </div>
    <div class="main-div-transparant" v-if="testStep === 3">
      <div class="content-div">
        <ExplanationComponent @nextPage="increaseStep" :test="test"/>
      </div>
      <div class="logo-div">
        <img class="logo" src="@/images/logo/motus.png" alt="MOTUS">
      </div>
    </div>
    <div class="main-div-transparant" v-if="testStep === 4">
      <div class="content-div" v-if="test.questionSet !== undefined">
        <DilemmaComponent @nextPage="increaseStep" @previousPage="previousStep" @saveAnswer="saveAnswer"
                          :key="questions[questionStep].id"
                          :test="test" :questionStep="questionStep"
                          :question="questions[questionStep]"
                          :oldAnswer="currentAnswer"
        />
      </div>
      <div class="logo-div">
        <img class="logo" src="@/images/logo/motus.png" alt="MOTUS">
      </div>
    </div>
    <div class="main-div-transparant" v-if="testStep === 5">
      <div class="content-div">
        <RatingExplanationComponent @nextPage="increaseStep" @previousPage="previousStep" :test="test"/>
      </div>
      <div class="logo-div">
        <img class="logo" src="@/images/logo/motus.png" alt="MOTUS">
      </div>
    </div>
    <div class="main-div-transparant" v-if="testStep === 6">
      <div class="content-div" v-if="test.questionSet !== undefined">
        <RatingComponent @nextPage="increaseStep" @previousPage="previousStep" @saveAnswer="saveRatingAnswer"
                         :key="ratingQuestions[questionStep].id"
                         :test="test" :questionStep="questionStep"
                         :ratingQuestion="ratingQuestions[questionStep]"
                         :oldAnswer="currentAnswer"
        />
      </div>
      <div class="logo-div">
        <img class="logo" src="@/images/logo/motus.png" alt="MOTUS">
      </div>
    </div>
    <div class="main-div-transparant" v-if="testStep === 7">
      <div class="content-div">
        <ScreenOutComponent @nextPage="increaseStep" :test="test" :respondent="respondent"/>
      </div>
      <div class="logo-div">
        <img class="logo" src="@/images/logo/motus.png" alt="MOTUS">
      </div>
    </div>
    <div class="main-div-transparant" v-if="testStep === 8">
      <div class="content-div">
        <ThankYouComponent @nextPage="increaseStep" :test="test"/>
      </div>
      <div class="logo-div">
        <img class="logo" src="@/images/logo/motus.png" alt="MOTUS">
      </div>
    </div>
  </div>
</template>

<script>
import RespondentService from '@/js/services/unSecureServices/RespondentService';
import AnswerService from '@/js/services/unSecureServices/AnswerService';
import WelcomeComponent from '@/js/components/elements/respondent/WelcomeComponent';
import ScreenOutComponent from '@/js/components/elements/respondent/ScreenOutComponent.vue';
import IntroductionComponent from '@/js/components/elements/respondent/IntroductionComponent.vue';
import ExplanationComponent from '@/js/components/elements/respondent/ExplanationComponent.vue';
import DilemmaComponent from '@/js/components/elements/respondent/DilemmaComponent.vue';
import RatingComponent from '@/js/components/elements/respondent/RatingComponent.vue';
import ThankYouComponent from '@/js/components/elements/respondent/ThankYouComponent.vue';
import RatingExplanationComponent from "@/js/components/elements/respondent/RatingExplanationComponent.vue";

export default {
  name: 'RespondentView',
  components: {
    WelcomeComponent,
    IntroductionComponent,
    ExplanationComponent,
    DilemmaComponent,
    RatingExplanationComponent,
    RatingComponent,
    ScreenOutComponent,
    ThankYouComponent,
  },
  data() {
    return {
      test: {
        company: {},
      },
      //TODO: Should be at 1
      testStep: 1,
      questionStep: 0,
      answers: [],
      currentAnswer: undefined,
      respondent: {},
      questions: [],
      ratingQuestions: [],
    };
  },

  async created() {
    this.test = await RespondentService.getTestFromSlug(this.$route.params.testName, this.$route.params.companyName);

    this.test.questionSet.questions.forEach(question => this.questions.push(question))
    this.questions = this.shuffle(this.questions);

    this.test.questionSet.ratingQuestions.forEach(question => this.ratingQuestions.push(question))
    this.ratingQuestions = this.shuffle(this.ratingQuestions);
  },
  computed: {
    getLogoImage() {
      return this.test.logoLocation;
    },
  },
  methods: {
    previousStep() {
      if (this.testStep === 4 && this.questionStep > 0) {
        this.questionStep--;
        this.currentAnswer = this.answers.find(answer => {
          return answer.questionId === this.questions[this.questionStep].id;
        });
      } else if (this.testStep === 6 && this.questionStep > 0) {
        this.questionStep--;
        this.currentAnswer = this.answers.find(answer => {
          return answer.ratingQuestionId === this.ratingQuestions[this.questionStep].id;
        });
      } else if (this.testStep === 6 && this.questionStep <= 0) {
        this.questionStep = 17;
        this.currentAnswer = this.answers.find(answer => {
          return answer.questionId === this.questions[this.questionStep].id;
        });
        this.testStep--;
      } else {
        this.testStep--;
      }
    },
    async increaseStep() {
      if (this.testStep === 4 && this.questionStep <= 16) {
        this.questionStep++;
        this.currentAnswer = this.answers.find(answer => {
          return answer.questionId === this.questions[this.questionStep].id;
        });
      } else if (this.testStep === 6 && this.questionStep <= 10) {
        this.questionStep++;
        this.currentAnswer = this.answers.find(answer => {
          return answer.ratingQuestionId === this.ratingQuestions[this.questionStep].id;
        });
      } else if (this.testStep === 6 && this.questionStep === 11) {
        let result = await AnswerService.addAnswers(this.answers, this.test.id);
        this.respondent = result.respondent;
        if (result.status.success) {
          if (!this.test.screenOutActive) {
            this.testStep++;
            this.testStep++;
          } else {
            this.testStep++;
          }
        } else {
          alert('Something went wrong, please close this page and open it again');
        }
      } else {
        this.questionStep = 0;
        this.testStep++;
      }
    },
    saveAnswer(inputAnswer) {
      let foundIndex = this.answers.findIndex(answer => answer.questionId === inputAnswer.questionId);
      if (foundIndex === -1) {
        this.answers.push(inputAnswer);
      } else {
        this.answers[foundIndex].selectedOption = inputAnswer.selectedOption;
        this.answers[foundIndex].points = inputAnswer.points;
        this.answers[foundIndex].archetype = inputAnswer.archetype;
      }
    },
    saveRatingAnswer(inputAnswer) {
      let foundIndex = this.answers.findIndex(answer => answer.ratingQuestionId === inputAnswer.ratingQuestionId);
      if (foundIndex === -1) {
        this.answers.push(inputAnswer);
      } else {
        this.answers[foundIndex].selectedOption = inputAnswer.selectedOption;
        this.answers[foundIndex].points = inputAnswer.points;
        this.answers[foundIndex].archetype = inputAnswer.archetype;
      }
    },
    shuffle(array) {
      let currentIndex = array.length, randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex !== 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }

      return array;
    },
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/app.scss';

.row-div {
  display: flex;
  flex-direction: row;
}

.title-div-small {
  margin: 20px;
}

.main-div-transparant {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  word-break: break-word;

  @media (min-width: $vw-tablet) {
    width: 60vw;
  }

  @media (min-width: $vw-desktop) {
    width: 40vw;
  }
}

.main-div-black {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $colorBlack;
  width: 100vw;
  height: 100vh;
  word-break: break-word;

  @media (min-width: $vw-tablet) {
    width: 40vw;
  }

  @media (min-width: $vw-desktop) {
    width: 40vw;
  }
}

.content-div {
  align-items: center;
  margin: 20px;
}

.logo-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  @media (max-width: $vw-tablet) {
    padding-top: 3rem;
    padding-bottom: 6rem;
  }
}

.logo {
  width: 100px;
  height: auto;
  margin: 10px;

  @media (min-width: $vw-tablet) {
    position: absolute;
    right: 25px;
    bottom: 25px;
    width: 250px;
  }

  @media (min-width: $vw-desktop) {
    position: absolute;
    right: 50px;
    bottom: 50px;
    width: 250px;
  }
}
</style>
