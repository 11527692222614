import ApolloClient from '@/js/graphql/ApolloClient';
import {getTestResults} from '@/js/graphql/queries';
import {utils} from 'xlsx';

class TestResultsService {
    static async downloadTestResults(testId) {
        const response = await ApolloClient.defaultClient.query({
            query: getTestResults,
            variables: {
                'testId': testId,
            },
        });

        return response.data.getTestResults;
    }

    static async modifyWorkbook(workbook, test) {
        const archetypeScores = {
            OUTLAW: 0,
            EXPLORER: 0,
            CREATOR: 0,
            HERO: 0,
            MAGICIAN: 0,
            SAGE: 0,
            RULER: 0,
            INNOCENT: 0,
            EVERYMAN: 0,
            CAREGIVER: 0,
            LOVER: 0,
            JESTER: 0,
        };
        const archetypeRankings = {...archetypeScores}; // Copies the structure for rankings

        test.respondents.forEach((respondent) => {
            respondent.answers.forEach((answer) => {
                if (answer.question && respondent.suitable) {
                    if (archetypeScores.hasOwnProperty(answer.archetype)) {
                        archetypeScores[answer.archetype] += answer.points;
                    }
                } else if (answer.ratingQuestion && respondent.suitable) {
                    if (archetypeRankings.hasOwnProperty(answer.archetype)) {
                        archetypeRankings[answer.archetype] += answer.points;
                    }
                }
            });
        });

        // Create percentages for archetypeScores and writes it to Excel file
        const totalScorePoints = Object.values(archetypeScores).reduce((acc, score) => acc + score, 0);
        for (const archetype in archetypeScores) {
            archetypeScores[archetype] = (((archetypeScores[archetype] / totalScorePoints) * 100).toFixed(1)).replace('.', ',') + '%';
        }
        let archetypeScoresWorksheet = utils.json_to_sheet([archetypeScores]);
        utils.book_append_sheet(workbook, archetypeScoresWorksheet, 'Dilemma results (suitable)');

        // Writes the values for the ranking results into the Excel file
        let archetypeRankingsWorksheet = utils.json_to_sheet([archetypeRankings]);
        utils.book_append_sheet(workbook, archetypeRankingsWorksheet, 'Ranking results (suitable)');

        // Question tab
        let questionList = []
        test.questionSet.questions.forEach(loopQuestion => {
            let question = {
                'Question ID': loopQuestion.id,
                'Left Archetype': loopQuestion.leftArchetype.archetype,
                'Right Archetype': loopQuestion.rightArchetype.archetype,
            }
            questionList.push(question);
        })
        let worksheetQuestions = utils.json_to_sheet(questionList);
        utils.book_append_sheet(workbook, worksheetQuestions, 'Compared archetype questions');

        // Raw Answer data tab
        let answers = []
        test.respondents.forEach(respondent => {
            let newAnswer = {
                "Respondent ID": respondent.id,
                "Suitable": respondent.suitable ? "Ja" : "Nee",
            };
            // Screenout answers
            respondent.screenOutAnswers.forEach(screenOutAnswer => {
                newAnswer[screenOutAnswer.screenOutQuestion.questionValue] = screenOutAnswer.answer;
            })

            // If no answers are entered yet, filter first
            if (answers.length === 0) {
                let sortedAnswers = this.sortAnswers(respondent.answers);

                sortedAnswers.forEach(answer => {
                    if (answer.question) {
                        newAnswer["Answer Q" + answer.question.id] = answer.archetype;
                    } else if (answer.ratingQuestion) {
                        newAnswer[answer.ratingQuestion.archetype.archetype] = answer.points;
                    }
                });
            } else {
                respondent.answers.forEach(answer => {
                    if (answer.question) {
                        newAnswer["Answer Q" + answer.question.id] = answer.archetype;
                    } else if (answer.ratingQuestion) {
                        newAnswer[answer.ratingQuestion.archetype.archetype] = answer.points;
                    }
                })
            }
            answers.push(newAnswer);
        });
        let worksheetAnswers = utils.json_to_sheet(answers);
        utils.book_append_sheet(workbook, worksheetAnswers, 'Raw Answers');
    }

    static sortAnswers(answers) {
        return answers.slice().sort((a, b) => {
            if (a.question && b.question) {
                // Both answers are questions
                return a.question.id - b.question.id;
            } else if (a.ratingQuestion && b.ratingQuestion) {
                // Both answers are rating questions
                return a.ratingQuestion.id - b.ratingQuestion.id;
            } else if (a.question && !b.question) {
                // Only a is a question, so it comes first
                return -1;
            } else if (!a.question && b.question) {
                // Only b is a question, so it comes first
                return 1;
            } else {
                // Both answers are rating questions, so they are considered equal
                return 0;
            }
        });
    }
}


export default TestResultsService;
