import ApolloClient from '@/js/graphql/ApolloClient';
import { addScreenOutQuestions } from '@/js/graphql/mutations';

class ScreenOutQuestionService {
    static async addScreenOutQuestions(screenOutQuestions, testId) {
        if (screenOutQuestions.length <= 0) {
            return false;
        }

        let newScreenOutQuestionsArray = [];

        screenOutQuestions.forEach((screenOutQuestion) => {
            let newObject = this.setupScreenOutQuestionObject(screenOutQuestion, testId);
            newScreenOutQuestionsArray.push(newObject);
        });

        const response = await ApolloClient.defaultClient.mutate({
            mutation: addScreenOutQuestions,
            variables: {
                'input': newScreenOutQuestionsArray,
            },
        });

        return response.data;
    }

    static setupScreenOutQuestionObject(screenOutQuestion, testId) {
        let newScreenOutQuestion = {};
        newScreenOutQuestion.questionValue = screenOutQuestion.questionValue;
        newScreenOutQuestion.possibleAnswers = [];
        newScreenOutQuestion.correctAnswers = [];
        newScreenOutQuestion.testId = testId;
        switch (screenOutQuestion.selectedOption) {
            case 'Numerieke schaal':
                newScreenOutQuestion.questionType = 'NUMERICRANGE';
                newScreenOutQuestion.correctAnswers.push(screenOutQuestion.numeriekeRangeCorrectMinValue);
                newScreenOutQuestion.correctAnswers.push(screenOutQuestion.numeriekeRangeCorrectMaxValue);
                break;
            case 'Ja/Nee':
                newScreenOutQuestion.questionType = 'YESNO';
                newScreenOutQuestion.possibleAnswers = ['Yes', 'No'];
                newScreenOutQuestion.correctAnswers = screenOutQuestion.yesNoCorrectAnswer;
                break;
            case 'Dropdown Menu':
                newScreenOutQuestion.questionType = 'DROPDOWN';
                screenOutQuestion.dropdownItems.forEach(function (dropdownItem) {
                    newScreenOutQuestion.possibleAnswers.push(dropdownItem.textValue);
                    if (dropdownItem.validAnswer) {
                        newScreenOutQuestion.correctAnswers.push(dropdownItem.textValue);
                    }
                })
                break;
        }
        return newScreenOutQuestion;
    }
}

export default ScreenOutQuestionService;
