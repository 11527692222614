import ApolloClient from '@/js/graphql/UnsecureApolloClient';
import { getTestBySlug } from '@/js/graphql/queries';

class RespondentService {
    static async getTestFromSlug(testName, companyName) {
        const response = await ApolloClient.defaultClient.query({
            query: getTestBySlug,
            variables: {
                'input': {
                    'titleSlug': testName,
                    'companyNameSlug': companyName,
                },
            },
        });

        return response.data.getTestBySlug.test;
    }
}

export default RespondentService;
