<template>
  <div class="screenout-div">
    <div class="title-div-medium">
      <b>
        {{ $t('ScreenOutComponent.TitlePartOne') }}
        <em class="title-em-pink">{{ $t('ScreenOutComponent.TitlePartTwo') }}</em>
        {{ $t('ScreenOutComponent.TitlePartThree') }}
      </b>
    </div>
    <div class="text-div-smallsquashed">
      <div class="info-div">
        <img class="alert-img" src="@/images/icons/AlertCircleWhite.png" alt="i"/>
        {{ $t('ScreenOutComponent.AnonymousData') }}
      </div>
    </div>
    <div class="content-div">
      <div class="text-div-small" v-if="test.screenOutQuestions !== undefined">
        <div class="screenoutquestion-div"
             v-for="(screenOutQuestion, index) in test.screenOutQuestions" :key="index">
          <ScreenOutQuestionComponent ref="screenOutQuestionsRef" :screenOutQuestion="screenOutQuestion"/>
        </div>
      </div>
      <div class="text-div-smallsquashed">
        <em class="title-em-black" v-if="containsNullAnswers">{{
            $t('ScreenOutComponent.ContainsNullAnswers')
          }}</em>
      </div>
    </div>
    <div class="button-div">
      <div class="button-wrapper--right" v-on:click="nextStep">
        <button class="next-button">
          <span class="button-span">{{ $t('ScreenOutComponent.ButtonNextText') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ScreenOutQuestionComponent from '@/js/components/elements/respondent/ScreenOutQuestionComponent.vue';
import ScreenOutAnswerService from '@/js/services/unSecureServices/ScreenOutAnswerService';

export default {
  name: 'ScreenOutComponent',
  components: {
    ScreenOutQuestionComponent,
  },
  props: {
    test: {},
    respondent: {},
  },
  data() {
    return {
      containsNullAnswers: false,
      suitableRespondent: true,
      screenOutAnswers: [],
    };
  },
  methods: {
    async nextStep() {
      if (!this.checkAnswers()) {
        let result = await ScreenOutAnswerService.addScreenOutAnswers(this.screenOutAnswers, this.respondent.id, this.suitableRespondent);
        if (result.status.success) {
          this.$emit('nextPage');
        } else {
          console.error(result.status.errorMessage);
          alert('Something went wrong while sending the data up to the API');
        }
      } else {
        console.error('empty values found');
      }
    },
    checkAnswers() {
      this.containsNullAnswers = false;
      this.screenOutAnswers = [];

      this.$refs.screenOutQuestionsRef.forEach(screenOutQuestion => {
        if (screenOutQuestion.checkIfAnswerIsNull()) {
          this.containsNullAnswers = true;
        } else {
          if (!screenOutQuestion.validateAnswer()) {
            this.suitableRespondent = false;
          }
          this.screenOutAnswers.push(screenOutQuestion.getScreenOutAnswer());
        }
      });

      return this.containsNullAnswers;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/app.scss";

.screenout-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .text-div-smallsquashed {
    margin-bottom: 0;

    .info-div {
      margin-top: 1rem;
      background: transparent;
      color: $colorWhite;
    }
  }

  .content-div {
    background-color: $colorPink;
    padding: 25px 15px;
    margin-bottom: 2rem;
    min-height: 60%;
    border-radius: 25px;

    .text-div-smallsquashed {
      margin-bottom: 0;
    }

    .screenoutquestion-div {
      margin-top: 1rem;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .button-div {
    display: flex;
    justify-content: flex-end;
    width: 90%;

    @media (min-width: $vw-tablet) {
      margin-top: 4rem;
      margin-bottom: 8rem;
    }

    .next-button {
      @extend .button-white--right;
      padding: 1rem;
      height: 3rem;
    }
  }
}

</style>
