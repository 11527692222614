import gql from 'graphql-tag'

export const loginUser = gql`
    mutation loginUser ($input: LoginUserInput!){
        login(input: $input){
            token
            user{
                id
                email
                company{
                    name
                }
                roles
            }
            status{
                success
                errorMessage
            }
        }
    }
`

export const createAccount = gql`
    mutation createUser ($input: RegisterUserInput!){
        createUser(input: $input){
            user{
                id
                email
                createdAt
                lastLoggedIn
                admin
                company {
                    name
                }
            }
            status{
                success
                errorMessage
            }
        }
    }
`

export const addTest = gql `
    mutation createTest ($input: CreateTestInput!){
        createTest(input: $input){
            test{
                id
                company{
                    name
                    nameSlug
                }
                title
                companyTitleSlug
                description
                createdAt
                lastUpdatedAt
                endDate
                hasEnded
                screenOutActive
                rewardActive
                respondentsCount
            }
            status{
                success
                errorMessage
            }
        }
    }
`

export const stopTest = gql `
    mutation stopTest($id: ID!){
        stopTest(id: $id){
            test {
                id
                company{
                    name
                    nameSlug
                }
                title
                companyTitleSlug
                description
                createdAt
                lastUpdatedAt
                endDate
                hasEnded
                screenOutActive
                rewardActive
                respondentsCount
            }
            status{
                success
                errorMessage
            }
        }
    }
`

export const openTest = gql `
    mutation openTest($id: ID!){
        openTest(id: $id){
            test {
                id
                company{
                    name
                    nameSlug
                }
                title
                companyTitleSlug
                description
                createdAt
                lastUpdatedAt
                endDate
                hasEnded
                screenOutActive
                rewardActive
                respondentsCount
            }
            status{
                success
                errorMessage
            }
        }
    }
`

export const addScreenOutQuestions = gql `
    mutation addScreenOutQuestions($input: [ScreenOutQuestionInput!]!){
        addScreenOutQuestions(input: $input){
            screenOutQuestion{
                questionValue
                test {
                    title
                }
                possibleAnswers
                correctAnswers
            },
            status{
                success
                errorMessage
            }
        }
    }
`

export const sendRewardEmail = gql`
    mutation sendRewardEmail($input: AddNewRewardEmail!){
        sendRewardEmail(input: $input){
            status {
                success
                errorMessage
            }
        }
    }
`

export const addAnswers = gql `
    mutation addAnswers($input: [AnswerInput]!, $testId: ID!){
        addAnswers(input: $input, testId: $testId){
            respondent {
                id
            }
            answers {
                question{
                    id
                }
                ratingQuestion{
                    id
                }
                points
                archetype
            }
            status {
                success
                errorMessage
            }
        }
    }
`

export const addScreenOutAnswers = gql `
    mutation addScreenOutAnswers($input: [ScreenOutAnswerInput!]!, $respondentId: ID!, $suitable: Boolean!){
        addScreenOutAnswers(input: $input, respondentId: $respondentId, suitable: $suitable){
            screenOutAnswers{
                respondent {
                    id
                    suitable
                }
                id
                answer
            },
            status{
                success
                errorMessage
            }
        }
    }
`

export const addCompany = gql `
    mutation addCompany($name: String!){
        addCompany(name: $name){
            company{
                name
                archetype
                users {
                    id
                }
                tests {
                    title
                }
            },
            status{
                success
                errorMessage
            }
        }
    }
`
