import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
    persist: true,
    state: () => {
        return {
            user: '',
        };
    },
    getters: {
        getUser() {
            return this.user;
        },
    },
    actions: {
        async setUser(user) {
            this.user = user;
        },
        async isAdmin() {
            if (this.user.roles.includes("ROLE_ADMIN")){
                return true;
            }
        },
    },
});
