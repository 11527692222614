<template>
    <div class="screenOutDiv">
        <div class=screenouttitle-div>
            <span class="modal-inputlabel-font">{{ $t('ScreenOutQuestion.QuestionType') }} </span>
            <button class="removequestion-button" v-on:click="removeCurrentScreenOutQuestion(index)">
                <img class="trashbin-img" src="@/images/icons/TrashbinIcon.png" alt="X">
            </button>
        </div>
        <FormKit
            type="select"
            :placeholder="$t('ScreenOutQuestion.QuestionType')"
            :options="screenOutOptions"
            v-model="screenOutQuestion.selectedOption"
        />
        <div v-show="screenOutQuestion.selectedOption === 'Numerieke schaal'">
            <FormKit
                type="text"
                :label="$t('ScreenOutQuestion.QuestionText')"
                :placeholder="$t('ScreenOutQuestion.QuestionText')"
                v-model="screenOutQuestion.questionValue"
                validation="required"
            />
            <div class="formrow-div">
                <FormKit
                    type="number"
                    :label="$t('ScreenOutQuestion.NumericRangeCorrectMinValue')"
                    :placeholder="$t('ScreenOutQuestion.NumericRangeCorrectMinValue')"
                    v-model="screenOutQuestion.numeriekeRangeCorrectMinValue"
                    min="0"
                    step="1"
                />
                <FormKit
                    type="number"
                    :label="$t('ScreenOutQuestion.NumericRangeCorrectMaxValue')"
                    :placeholder="$t('ScreenOutQuestion.NumericRangeCorrectMaxValue')"
                    v-model="screenOutQuestion.numeriekeRangeCorrectMaxValue"
                    min="0"
                    step="1"
                />
            </div>
        </div>
        <div v-show="screenOutQuestion.selectedOption === 'Ja/Nee'">
            <FormKit
                type="text"
                :label="$t('ScreenOutQuestion.QuestionText')"
                :placeholder="$t('ScreenOutQuestion.QuestionText')"
                v-model="screenOutQuestion.questionValue"
                validation="required"
            />
            <FormKit
                type="select"
                :label="$t('ScreenOutQuestion.CorrectYesNoAnswer')"
                :placeholder="$t('ScreenOutQuestion.CorrectYesNoAnswer')"
                v-model="screenOutQuestion.yesNoCorrectAnswer"
                :options="['Yes', 'No']"
            />
        </div>
        <div v-show="screenOutQuestion.selectedOption === 'Dropdown Menu'">
            <FormKit
                type="text"
                :label="$t('ScreenOutQuestion.QuestionText')"
                :placeholder="$t('ScreenOutQuestion.QuestionText')"
                v-model="screenOutQuestion.questionValue"
                validation="required"
            />
            <div class="dropdownoptions-div" v-for="(dropdown, index) in screenOutQuestion.dropdownItems">
                <input class="dropdownitem-checkbox" type="checkbox" v-model="dropdown.validAnswer">
                <input class="dropdownitem-textinput" type="text" v-model="dropdown.textValue"
                       :placeholder="$t('ScreenOutQuestion.DropdownItemPlaceholder') + (index + 1) ">
                <button type="button" class="dropdownminus-button" v-on:click="decreaseDropdown(index)">-</button>
            </div>
            <button type="button" class="adddropdownitem-button" v-on:click="increaseDropdown">
                <img src="@/images/icons/PlusIcon.png" alt="+">
                <span class="adddropdownitem-span">{{ $t('ScreenOutQuestion.AddNewDropdownItem') }}</span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ScreenOutQuestion',
    components: {},
    props: {
        screenOutQuestion: {},
        index: 0,
    },
    data() {
        return {
            screenOutOptions: ['Numerieke schaal', 'Ja/Nee', 'Dropdown Menu'],
        };
    },
    methods: {
        increaseDropdown() {
            this.screenOutQuestion.dropdownItems.push(
                {
                    textValue: '',
                    validAnswer: false,
                },
            );
        },
        decreaseDropdown(index) {
            this.screenOutQuestion.dropdownItems.splice(index, 1);
        },
        removeCurrentScreenOutQuestion(index) {
            this.$emit('removeThisScreenOutQuestion', index);
        }
    },
};
</script>

<style scoped lang="scss">
@import '@/styles/app.scss';

.screenOutDiv {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  background-color: $colorBrokenWhite;
  box-shadow: 3px 3px 5px -2px rgba(0, 0, 0, 0.11);
  margin-bottom: 32px;
  padding: 31px 40px;

  .screenouttitle-div {
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    justify-content: space-between;
    margin-bottom: 8px;

    .removequestion-button {
      border: none;
      background: none;

      &:hover {
        cursor: pointer;
      }

      .trashbin-img {
        width: 22px;
        height: 28px;
      }
    }
  }

  .formrow-div {
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    justify-content: space-between;
  }

  .dropdownoptions-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .dropdownitem-checkbox {
      min-height: 26px;
      min-width: 26px;
      margin: 10px;
      accent-color: $colorBlack;

      &:hover {
        accent-color: $colorHoverGray;
      }
    }

    .dropdownitem-textinput {
      @extend .modal-input-font;
      background-color: $colorBrokenWhite;
      color: $colorBlack;
      height: 60px;
      width: 95%;
      border: none;
      padding-left: 24px;
      border-bottom: 1px solid $colorBlack;
      margin-bottom: 16px;
    }

    .dropdownminus-button {
      background-color: $colorMistyGray;
      color: $colorAntGray;
      border: 1px solid $colorAntGray;
      border-radius: 5px;
      min-height: 26px;
      min-width: 26px;
      margin: 10px;

      &:hover {
        background-color: $colorSilverGray;
        cursor: pointer;
      }
    }
  }

  .adddropdownitem-button {
    display: flex;
    align-content: flex-start;
    align-items: center;
    background-color: $colorBrokenWhite;
    box-shadow: 3px 3px 5px -2px rgba(0, 0, 0, 0.11);
    border: none;
    min-height: 68px;
    width: 100%;
    padding-left: 26px;

    &:hover {
      background-color: $colorMistyGray;
      cursor: pointer;
    }

    .adddropdownitem-span {
      margin-left: 20px;
    }
  }
}

</style>
