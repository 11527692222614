<template>
  <div>
    <div class="title-div-medium">
      <b>
        {{ $t('IntroductionComponent.TitlePartOne') }}
        <em class="title-em-pink">{{ test.company.name }}</em>
      </b>
    </div>

    <div class="videotext-div">
      <div class="text-div-small">
        Het CBR werkt elke dag aan verkeersveiligheid in Nederland. Met diverse campagnes helpen ze jongeren door het geven van tips met het behalen van het autorijbewijs. Beginnende bestuurders worden gewezen op de gevolgen als je gaat rijden onder invloed van alcohol & drugs.
      </div>
    </div>
    <div class="introductionvideo-div" v-if="test.demoVideoLink">
      <iframe :src="test.demoVideoLink"/>
    </div>
    <div class="text-div-small" v-if="!test.demoVideoLink">
      {{ $t('IntroductionComponent.NoVideo') }}
    </div>

    <div class="text-div-smallsquashed">
      <div class="info-div">
        <img class="alert-img" src="@/images/icons/AlertCircle.png" alt="i"/>
        {{ $t('IntroductionComponent.InfoPartOne') }} {{ test.company.name }}.
      </div>
    </div>

    <div class="button-div">
      <div class="button-wrapper--right" v-on:click="nextStep">
        <button class="next-button">
          <span class="button-span">{{ $t('IntroductionComponent.ButtonNextText') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IntroductionComponent',
  props: {
    test: {},
  },
  data() {
    return {};
  },
  methods: {
    nextStep() {
      this.$emit('nextPage');
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/app.scss";

.content-div {
  align-content: center;

  .videotext-div {
    background-color: $colorPink;
    margin-top: 2rem;
    padding: 25px;
    min-height: 60%;
    border-radius: 25px;
    margin-bottom: 1rem;

    .text-div-small {
      margin-bottom: 0;
    }
  }

  .text-div-smallsquashed {
    justify-content: center;
    display: flex;

    .info-div {
      @extend .body-small-info;
      margin-top: 2rem;
      margin-bottom: 0.5rem;
    }
  }


  .button-div {
    display: flex;
    justify-content: center;
    width: 100%;

    @media (min-width: $vw-tablet) {
      margin-top: 4rem;
      margin-bottom: 8rem;
    }

    .next-button {
      @extend .button-white--right;
      padding: 1rem;
      height: 3rem;
    }
  }

  .introductionvideo-div {
    position: relative;
    padding-bottom: 56.25%;
    margin-bottom: 2rem;
    border: none;
    border-radius: 0.5rem;
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }

  .introductionvideo-div iframe, .introductionvideo-div object, .introductionvideo-div embed {
    position: absolute;
    border: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

</style>
