<template>
  <div>
    <div class="title-div-medium">
      <b>
        <em class="title-em-pink">{{ $t('WelcomeComponent.TitlePartOne') }}</em>
        {{ $t('WelcomeComponent.TitlePartTwo') }}
      </b>
    </div>
    <div class="content-div">
      <div class="text-div-small">
        {{ $t('WelcomeComponent.IntroPartOne') }}
        {{ test.company.name }}.
      </div>
      <div class="text-div-small">
        {{ $t('WelcomeComponent.IntroPartTwo') }} <br>
        {{ $t('WelcomeComponent.IntroPartThree') }}
      </div>
      <div class="text-div-small" v-if="test.rewardActive">
        {{ $t('WelcomeComponent.IntroPartReward') }}
      </div>
      <div class="text-div-small remove-margin">
        <em class="text-em-black">{{ $t('WelcomeComponent.IntroPartFour') }}</em>
      </div>
    </div>
    <div class="button-div">
      <div class="button-wrapper--right">
        <button class="next-button" v-on:click="nextStep">
          <span class="button-span">{{ $t('WelcomeComponent.ButtonNextText') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WelcomeComponent',
  props: {
    test: {},
  },
  data() {
    return {};
  },
  methods: {
    nextStep() {
      this.$emit('nextPage');
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/app.scss";

.content-div {
  background-color: $colorPink;
  margin-top: 4rem;
  padding: 25px;
  min-height: 60%;
  border-radius: 25px;

  .text-div-small {
    margin-bottom: 1.5rem;
  }

  .button-div {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;

    @media (min-width: $vw-tablet) {
      margin-top: 4rem;
      margin-bottom: 8rem;
    }

    .next-button {
      @extend .button-white--right;
      padding: 1rem;
      height: 3rem;
    }
  }
}
</style>
