<template>
    <div class='main-div'>
        <button class='modal-button-open' v-on:click='openModal'>{{
                $t('CreateCompanyModal.OpenModal')
            }}
        </button>
        <div class='modal-div' v-if='modalShowing' v-on:click='closeModal'>
            <div class='modalcontent-div' @click.stop=''>
                <span class="modal-title">{{ $t('CreateCompanyModal.ModalTitle') }}</span>
                <span class="modal-description">{{ $t('CreateCompanyModal.ModalDescription') }}</span>
                <FormKit
                    type='form'
                    :submit-label="$t('CreateCompanyModal.CreateNewCompanyButton')"
                    @submit='createCompany'
                    :submit-attrs="{
                        inputClass: 'formkit-input',
                    }"
                >
                    <FormKit
                        type='text'
                        name='companyName'
                        :label="$t('CreateCompanyModal.CompanyNameLabel')"
                        :placeholder="$t('CreateCompanyModal.CompanyNamePlaceholder')"
                        v-model='company.name'
                        validation='required'
                    />
                    <span class="modal-errormessage">{{ errorMessage }}</span>
                </FormKit>
                <div class="button-div">
                    <button v-on:click="closeModal" class="cancel-button">
                        {{ $t('CreateCompanyModal.CancelCreateCompanyButton') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CompanyService from '@/js/services/secureServices/CompanyService';

export default {
    name: 'CreateCompanyModal',
    components: {},
    data() {
        return {
            modalShowing: false,
            company: {
                name: '',
            },
            errorMessage: '',
        };
    },
    async created() {
        this.companies = await CompanyService.getCompanyNames();
    },
    methods: {
        openModal() {
            this.modalShowing = true;
        },
        closeModal() {
            this.modalShowing = false;
        },
        async createCompany() {
            this.errorMessage = '';
            if (this.company.name !== '') {
                let result = await CompanyService.addCompany(this.company.name);

                if (result.addCompany.status.success) {
                    this.$emit('addCompanyToLists', result.addCompany.company);
                    this.closeModal();
                    this.company = {};
                } else {
                    this.errorMessage = this.$t('CreateCompanyModal.ExistingCompany');
                    console.error(result.addCompany.status.errorMessage);
                }
            } else {
                this.errorMessage = this.$t('CreateCompanyModal.NullInput');
            }
        },
    },
};
</script>

<style scoped lang='scss'>
@import '@/styles/app.scss';

.main-div {

  .modal-button-open {
    @extend .button-gray;
    width: 208px;
    height: 64px;
    margin: 24px 48px 19px 0;
    float: right;
  }

  .modal-div {
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .modalcontent-div {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      box-shadow: 0 0 0 50vmax rgba(0, 0, 0, .5);
      max-height: 81%;
      z-index: 1;
      background-color: $colorWhite;
      margin: auto;
      padding: 60px 75px;
      width: 550px;

      .modal-errormessage {
        @extend .modal-errormessage-font;
      }

      .button-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .cancel-button {
          @extend .modalcancel-button;
          @extend .modal-cancelbutton-font;
          height: 32px;
          width: 200px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
