<template>
    <div class="topbar-div">
        <h1>{{ $t('TestView.Title') }}</h1>
        <div class="topbarbuttons-div">
            <CreateTestModal @addTestToLists="addNewTestToList" v-if="userIsAdmin" />
        </div>
    </div>
    <div class="testcontent-div">
        <div class="testcollection-div">
            <span class="tabletitle-span">{{ $t('TestView.TableTitleRunningTests') }}</span>
            <span class="tablecount-span">{{ openTests.length }} {{ $t('TestView.total') }}</span>
            <table class="test-table" v-if="openTests.length >= 1">
                <tr>
                    <th></th>
                    <th>{{ $t('TestView.CompanyName') }}</th>
                    <th>{{ $t('TestView.TableTestTitle') }}</th>
                    <th>{{ $t('TestView.TotalRespondents') }}</th>
                    <th>{{ $t('TestView.LastUpdatedAt') }}</th>
                    <th>{{ $t('TestView.EndDate') }}</th>
                    <th>{{ $t('TestView.Link') }}</th>
                    <th>{{ $t('TestView.SeeResults') }}</th>
                    <th v-if="userIsAdmin">{{ $t('TestView.EndTest') }}</th>
                </tr>
                <tr v-for="test in openTests" :key="test.id">
                    <td>
                        <div class="checkbox-div">
                            <input class="tablecheckbox-input" type="checkbox">
                        </div>
                    </td>
                    <td>{{ test.company.name }}</td>
                    <td>{{ test.title }}</td>
                    <td>{{ test.respondentsCount }}</td>
                    <td>{{ test.lastUpdatedAt.slice(0, 10) }}</td>
                    <td>{{ test.endDate ? test.endDate.slice(0, 10) : $t('TestView.NoEndDate') }}</td>
                    <td>
                        <span class="clickable-span" v-on:click="copyToClipboard(test.companyTitleSlug)">
                            {{ $t('TestView.TestLink') }}
                            <img class="copyicon-img" src="@/images/icons/CopyIcon.png" />
                        </span>
                    </td>
                    <td>
                        <button class="intable-button" v-on:click="downloadTestResults(test)">
                            {{ $t('TestView.SeeResultsButton') }}
                        </button>
                    </td>
                    <td v-if="userIsAdmin">
                        <button class="intable-button" v-on:click="stopTest(test.id)">
                            {{ $t('TestView.EndTestButton') }}
                        </button>
                    </td>
                </tr>
            </table>
        </div>

        <div class="testcollection-div">
            <span class="tabletitle-span">{{ $t('TestView.TableTitleFinishedTests') }}</span>
            <span class="tablecount-span">{{ closedTests.length }} totaal</span>
            <table class="test-table" v-if="closedTests.length >= 1">
                <tr>
                    <th></th>
                    <th>{{ $t('TestView.CompanyName') }}</th>
                    <th>{{ $t('TestView.TableTestTitle') }}</th>
                    <th>{{ $t('TestView.TotalRespondents') }}</th>
                    <th>{{ $t('TestView.LastUpdatedAt') }}</th>
                    <th>{{ $t('TestView.EndDate') }}</th>
                    <th>{{ $t('TestView.SeeResults') }}</th>
                    <th>{{ $t('TestView.OpenTest') }}</th>
                </tr>
                <tr v-for="test in closedTests" :key="test.id">
                    <td>
                        <div class="checkbox-div">
                            <input class="tablecheckbox-input" type="checkbox">
                        </div>
                    </td>
                    <td>
                        {{ test.company.name }}
                    </td>
                    <td>
                        {{ test.title }}
                    </td>
                    <td>
                        {{ test.respondentsCount }}
                    </td>
                    <td>
                        {{ test.lastUpdatedAt.slice(0, 10) }}
                    </td>
                    <td>
                        {{ test.endDate ? test.endDate.slice(0, 10) : $t('TestView.NoEndDate') }}
                    </td>
                    <td>
                        <button class="intable-button" v-on:click="downloadTestResults(test)">
                            {{ $t('TestView.SeeResultsButton') }}
                        </button>
                    </td>
                    <td v-if="userIsAdmin">
                        <button class="intable-button" v-on:click="openTest(test.id)">
                            {{ $t('TestView.OpenTestButton') }}
                        </button>
                    </td>
                </tr>
            </table>
        </div>
        <div v-if="isPopupVisible" class="popup">
            <img class="popup-img" src="@/images/icons/AlertCircle.png" alt="i">
            <span class="popup-span">{{ popupMessage }}</span>
        </div>
    </div>
</template>

<script>
import CreateTestModal from '@/js/components/modals/CreateTestModal.vue';
import testService from '@/js/services/secureServices/TestService';
import rewardEmailService from '@/js/services/secureServices/RewardEmailService';
import accountService from '@/js/services/secureServices/AccountService';
import testResultsService from '@/js/services/secureServices/TestResultsService';
import { writeFile, utils } from 'xlsx';

export default {
    name: 'TestView',
    components: {
        CreateTestModal,
    },
    data() {
        return {
            openTests: [],
            closedTests: [],
            userIsAdmin: false,
            isPopupVisible: false,
            popupMessage: '',
        };
    },
    async created() {
        this.openTests = await testService.loadOpenTests();
        this.closedTests = await testService.loadClosedTests();
        this.userIsAdmin = await accountService.isUserAdmin();
    },
    methods: {
        addNewTestToList(test) {
            this.openTests.push(test);
        },
        async stopTest(testId) {
            if (confirm('Wil je de test echt afsluiten?') === true) {
                //Send to api to end the test
                const response = await testService.stopTest(testId);

                if (response.stopTest.test === null) {
                    console.error('Something went wrong - ' + response.status.errorMessage);
                    return;
                }

                if (response.stopTest.test.hasEnded) {
                    let foundTestIndex = this.openTests.findIndex((obj) => obj.id === testId);

                    //Remove from open test list
                    if (foundTestIndex > -1) {
                        this.openTests.splice(foundTestIndex, 1);
                    }

                    //Add to closed test list (first position)
                    this.closedTests.unshift(response.stopTest.test);
                }
            }
        },
        async openTest(testId) {
            if (confirm('Wil je de test echt heropenen?') === true) {
                //Send to api to end the test
                const response = await testService.openTest(testId);

                if (response.openTest.test === null) {
                    console.error('Something went wrong - ' + response.status.errorMessage);
                    return;
                }

                if (!response.openTest.test.hasEnded) {
                    let foundTestIndex = this.closedTests.findIndex((obj) => obj.id === testId);

                    //Remove from open test list
                    if (foundTestIndex > -1) {
                        this.closedTests.splice(foundTestIndex, 1);
                    }

                    //Add to closed test list (first position)
                    this.openTests.unshift(response.openTest.test);
                }
            }
        },
        copyToClipboard(slug) {
            let fullLink = window.location.origin + '/klant/' + slug;
            navigator.clipboard.writeText(fullLink);
            this.popupMessage = this.$t('TestView.CopyLinkClipBoardText');
            this.isPopupVisible = true;

            setTimeout(() => {
                this.isPopupVisible = false;
                this.popupMessage = '';
            }, 3000);
        },
        async downloadTestResults(test) {
            let result = await testResultsService.downloadTestResults(test.id);
            if (!result.status.success) {
                console.error(result.status.errorMessage);
                console.error('Something went wrong getting the data for test id: ' + test.id);
                return;
            }

            let workbook = utils.book_new();
            await testResultsService.modifyWorkbook(workbook, result.test);
            writeFile(workbook, 'Results-' + test.company.name + '-' + test.title + '.xlsx');
        },
        async getRandomRewardEmail(testId, rewardActive) {
            if (rewardActive) {
                let result = await rewardEmailService.getRewardEmail(testId);
                if (result.status.success) {
                    alert(result.email);
                } else {
                    alert('Something went wrong');
                }
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/styles/app.scss";

.topbar-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: $colorWhite;
  min-height: 107px;

  h1 {
    width: 80%;
    margin-left: 3rem;
    color: $colorBlack;
  }

  .topbarbuttons-div {
    width: 20%;

    button {
      @extend .button-black;
      width: 208px;
      height: 64px;
      margin: 24px 48px 19px 0;
      float: right;
    }
  }
}

.testcontent-div {
  display: flex;
  flex-direction: column;
  background-color: $colorBrokenWhite;
  margin: 20px;

  .popup {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 10px 25px;
    background-color: $colorBlack;

    .popup-img {
      padding-right: 10px;
    }

    .popup-span {
      @extend .popup-span-font;
    }
  }

  .download-button {
    @extend .button-gray;
    height: 30px;
    width: 200px;
    margin: 10px 0;
    border-radius: 5px;
    font-size: 20px;
    text-align: center;
  }

  .testcollection-div {
    display: flex;
    flex-direction: column;
    background-color: $colorWhite;
    padding: 72px 48px 48px 48px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    .tabletitle-span {
      @extend .table-title;
      color: $colorBlack;
      margin-bottom: 16px;
    }

    .tablecount-span {
      @extend .table-count;
    }

    .test-table {
      border-collapse: collapse;
      background-color: $colorWhite;
      width: 100%;
      margin-top: 16px;

      tr th {
        @extend .table-header;
        border-top: 1px solid $colorMistyGray;
        border-bottom: 1px solid $colorMistyGray;
        color: $colorSilverGray;
        text-align: left;
        padding: 6px;
      }

      tr td {
        @extend .table-row;
        text-align: left;
        padding: 6px;
        word-break: break-word;

        .checkbox-div {
          display: flex;
          justify-content: center;
          align-items: center;

          .tablecheckbox-input {
            height: 30px;
            width: 30px;
            border: 1px solid $colorSilverGray;
            border-radius: 5px;
            accent-color: $colorBlack;
            margin: 10px;
          }
        }

        .clickable-span {
          text-decoration: underline;
          cursor: pointer;

          &:hover {
            color: $colorSilverGray;
          }

          .copyicon-img {
            height: 22px;
            width: 19px;

          }
        }

        .intable-button {
          @extend .button-intable;
          padding: 16px;
        }
      }
    }
  }
}
</style>
