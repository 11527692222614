<template>
    <div class="topbar-div">
        <h1>{{ $t("TopbarComponent." + $route.meta.displayName) }}</h1>
        <div class="topbarbuttons-div">
            <button>
                {{ $t("TopbarComponent.LogoutButton") }}
            </button>
        </div>
    </div>
</template>

<script>
import AccountService from '@/js/services/secureServices/AccountService';

export default {
    name: 'TopbarComponent',
    components: {},
    data() {
        return {};
    },
    created() {
    },
    methods: {
    },
};
</script>

<style scoped lang="scss">
@import "@/styles/app.scss";

.topbar-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: $colorWhite;
  min-height: 107px;

  h1 {
    width: 80%;
    margin-left: 3rem;
    color: $colorBlack;
  }

  .topbarbuttons-div {
    width: 20%;

    button {
      @extend .button-black;
      width: 208px;
      height: 64px;
      margin: 24px 48px 19px 0;
      float: right;
    }
  }
}
</style>
