<template>
    <div class="navbar">
        <div class="navbartop-div">
            <img src="@/images/logo/logoPortal.png" alt="MOTUS" />
        </div>
        <ul class="navbar-ul">
            <li :class="$route.name === 'adminTest' ? 'navbar-li-selected' : 'navbar-li'"
                v-on:click="changeSelectedNavigation('adminTest')">
                <img v-if="$route.name === 'adminTest'" class="navbar-icon" src="@/images/icons/TestIconSelected.png" alt="" />
                <img v-if="$route.name !== 'adminTest'" class="navbar-icon" src="@/images/icons/TestIcon.png" alt="" />
                <span :class="$route.name === 'adminTest' ? 'navbar-span-selected' : 'navbar-span'">
                    {{ $t('NavbarComponent.Tests') }}
                </span>
            </li>
            <li :class="$route.name === 'adminCompany' ? 'navbar-li-selected' : 'navbar-li'"
                v-on:click="changeSelectedNavigation('adminCompany')">
                <img v-if="$route.name === 'adminCompany'" class="navbar-icon" src="@/images/icons/CompanyIconSelected.png" alt="" />
                <img v-if="$route.name !== 'adminCompany'" class="navbar-icon" src="@/images/icons/CompanyIcon.png" alt="" />
                <span :class="$route.name === 'adminCompany' ? 'navbar-span-selected' : 'navbar-span'">
                    {{ $t('NavbarComponent.Companies') }}
                </span>
            </li>
            <li :class="$route.name === 'adminQuestion' ? 'navbar-li-selected' : 'navbar-li'"
                v-on:click="changeSelectedNavigation('adminQuestion')">
                <img v-if="$route.name === 'adminQuestion'" class="navbar-icon" src="@/images/icons/QuestionIconSelected.png" alt="" />
                <img v-if="$route.name !== 'adminQuestion'" class="navbar-icon" src="@/images/icons/QuestionIcon.png" alt="" />
                <span :class="$route.name === 'adminQuestion' ? 'navbar-span-selected' : 'navbar-span'">
                    {{ $t('NavbarComponent.Questions') }}
                </span>
            </li>
            <li :class="$route.name === 'adminAccount' ? 'navbar-li-selected' : 'navbar-li'"
                v-on:click="changeSelectedNavigation('adminAccount')">
                <img v-if="$route.name === 'adminAccount'" class="navbar-icon" src="@/images/icons/AccountIconSelected.png" alt="" />
                <img v-if="$route.name !== 'adminAccount'" class="navbar-icon" src="@/images/icons/AccountIcon.png" alt="" />
                <span :class="$route.name === 'adminAccount' ? 'navbar-span-selected' : 'navbar-span'">
                    {{ $t('NavbarComponent.Accounts') }}
                </span>
            </li>
        </ul>
        <div class="bottom">
            <div class="logout-div" v-on:click="logout">
                <span>{{ $t('NavbarComponent.Logout') }} </span>
                <img src="@/images/icons/LogoutIcon.png" />
            </div>
        </div>
    </div>
</template>


<script>
import AccountService from '@/js/services/secureServices/AccountService';

export default {
    name: 'NavbarComponent',
    data() {
        return {
            answer: {},
        };
    },
    methods: {
        changeSelectedNavigation(inputNavigation) {
            this.selectedNavigation = inputNavigation;
            switch (inputNavigation){
                case "adminTest":
                    this.$router.push('/admin/test');
                    break;
                case "adminCompany":
                    this.$router.push('/admin/company');
                    break;
                case "adminQuestion":
                    this.$router.push('/admin/question');
                    break;
                case "adminAccount":
                    this.$router.push('/admin/account');
                    break;
            }
        },
        logout() {
            AccountService.logout();
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/styles/app.scss";

.navbar {
  background-color: $colorBlack;
  height: 100%;

  .navbartop-div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 107px;
  }

  .navbar-ul {
    list-style-type: none;
    margin: 0 16px;
    padding: 0;

    .navbar-li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      margin: 0;
      padding: 0;

      &:hover {
        background-color: $colorHoverGray;
        color: $colorWhite;
      }
    }

    .navbar-span {
      @extend .newake-font;
      display: block;
      color: $colorSilverGray;
      text-decoration: none;
      font-style: normal;
      font-weight: 450;
      font-size: 22px;
      line-height: 32px;
      padding: 18px;
    }

    .navbar-li-selected {
      @extend .navbar-li;
      background-color: $colorHoverGray;
    }

    .navbar-span-selected {
      @extend .navbar-span;
      color: $colorWhite;
    }

    .navbar-icon {
      width: 25px;
      height: 25px;
      margin-left: 10px;
    }
  }

  .bottom {
    position: absolute;
    bottom: 30px;
    left: 40px;

    .logout-div {
      position: relative;
      cursor: pointer;

      span {
        color: $colorWhite;
        font-style: normal;
        font-weight: 450;
        font-size: 22px;
        line-height: 56px;
      }

      img {
        position: absolute;
        left: 90px;
        bottom: 10px;
      }
    }
  }
}

</style>
