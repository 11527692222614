import { ApolloClient, createHttpLink, InMemoryCache, from } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { createApolloProvider } from '@vue/apollo-option';
import { setContext } from '@apollo/client/link/context';
import router from '@/js/routes/router';

const httpLink = createHttpLink({
    uri: '/graphql/',

});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('jwt-token');

    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const logoutLink = onError(({ networkError, operation, forward }) => {
    if (networkError && networkError.statusCode === 401) {
        localStorage.removeItem('jwt-token')
        router.push({ name: 'adminLogin' });
    }
    return forward(operation);
});

const cache = new InMemoryCache();

const apolloClient = new ApolloClient({
    link: from([logoutLink, authLink.concat(httpLink)]),
    cache,
    uri: '/graphql/',
});

const apolloProvider = createApolloProvider({
    defaultClient: apolloClient,
});

export default apolloProvider;
