<template>
    <div class="main-div">
        <div class="login-div">
            <div class="title-div-big">
                {{ $t('LoginView.Welcome') }}
            </div>
            <div class="logincontent-div">
                <div class="text-div">
                    {{ $t('LoginView.WelcomeText') }}
                </div>
                <input type="email" class="login-input" :placeholder="$t('LoginView.Email')" v-model="user.email">
                <input type="password" class="login-input" :placeholder="$t('LoginView.Password')"
                       v-model="user.password">
                <span class="errormessage-span"> {{ errorMessage }} </span>
                <button class="login-button" v-on:click="login">
                    <span class="button-span">{{ $t('LoginView.LoginButton') }}</span>
                    <img class="arrow-right" src="@/images/icons/RightArrow.png" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import userService from '@/js/services/secureServices/AccountService';
import { useUserStore } from '@/js/store/userStore';
import router from '@/js/routes/router';

export default {
    name: 'LoginView',
    data() {
        return {
            user: {
                email: '',
                password: '',
            },
            errorMessage: '',
        };
    },
    methods: {
        async login() {
            this.errorMessage = '';
            const data = await userService.login(this.user.email, this.user.password);

            if (data.login.status.success) {
                localStorage.removeItem('jwt-token');
                localStorage.setItem('jwt-token', data.login.token);

                const userStore = useUserStore();
                await userStore.setUser(data.login.user);

                router.push({ name: 'adminTest' });
            } else {
                this.errorMessage = this.$t('LoginView.LoginFailedError');
                console.error(data.login.status.errorMessage);
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/styles/app.scss";

.main-div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

.login-div {
  min-width: 400px;
  min-height: 300px;

  .logincontent-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-color: $colorBlack;
    border: 1px solid $colorWhite;
    border-radius: 10px;
    padding: 30px;

    .login-input {
      background-color: $colorBlack;
      border: 1px solid $colorWhite;
      border-radius: 5px;
      color: $colorWhite;
      width: 388px;
      height: 51px;
      margin-top: 1rem;
      padding: 5px 20px;

      &:focus {
        outline: none;
        border: 1px solid $colorPink;
      }
    }

    .text-div {
      margin: 10px;
    }

    .login-button {
      @extend .button-white;
      width: 426px;
      height: 75px;
      padding: 5px 20px;
      margin-top: 1rem;
    }

    .errormessage-span {
      color: $colorPink;
      margin: 10px;
    }
  }
}
</style>
