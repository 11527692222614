<template>
  <div class="dilemma-div">
    <div class="title-div-small">
      <b>
        {{ $t('DilemmaComponent.TitlePartOne') }}
        <em class="title-em-pink">{{ test.company.name }}</em>
      </b>
    </div>
    <div class="text-div">
      <div class="comparison-div" v-if="!loadingNextDilemma">
        <div class="archetype-div">
          <img :class="selectedOption === 1 ? 'archetype-img-selected' : 'archetype-img-option'"
               :src="require('@/images/questionsets/' + question.leftArchetype.imageLocation)"
               alt='Archetype image'
               v-on:click="changeSelectedOption(1)">
          <span :class="selectedOption === 1 ? 'dilemmaname-span-selected' : 'dilemmaname-span'"
                v-on:click="changeSelectedOption(1)">
            {{ question.leftArchetype.displayFirstName }}<br>
            {{ question.leftArchetype.displayLastName }}
          </span>
          <span class="dilemmadescription-span">
            {{ question.leftArchetype.description }}
          </span>
        </div>
        <div class="archetype-div">
          <img :class="selectedOption === 2 ? 'archetype-img-selected' : 'archetype-img-option'"
               :src="require('@/images/questionsets/' + question.rightArchetype.imageLocation)"
               alt='Archetype image'
               v-on:click="changeSelectedOption(2)">
          <span :class="selectedOption === 2 ? 'dilemmaname-span-selected' : 'dilemmaname-span'"
                v-on:click="changeSelectedOption(2)">
            {{ question.rightArchetype.displayFirstName }}<br>
            {{ question.rightArchetype.displayLastName }}
          </span>
          <span class="dilemmadescription-span">
            {{ question.rightArchetype.description }}
          </span>
        </div>
        <div class="other-div">
          {{ $t('DilemmaComponent.Or') }}
        </div>
      </div>
      <div class="loading-div" v-if="loadingNextDilemma">
        <img class="loading-gif" src="@/images/icons/LoadingIcon.gif" alt="Loading next question">
        {{ $t('DilemmaComponent.LoadingNextDilemmaPartOne') }}
        {{ questionStep + 1 }}
        {{ $t('DilemmaComponent.LoadingNextDilemmaPartTwo') }}
      </div>
    </div>
    <div class="text-div-smallsquashed" v-if="!loadingNextDilemma">
      <div class="info-div">
        <img class="alert-img" src="@/images/icons/AlertCircle.png" alt="i"/>
        {{ $t('DilemmaComponent.NoGoodNoBad') }}
      </div>
    </div>
    <div class="button-div" v-if="!loadingNextDilemma">
      <div class="button-wrapper--left" v-on:click="previousStep">
        <button class="previous-button">
          <span class="button-span">{{ $t('DilemmaComponent.ButtonPreviousText') }}</span>
        </button>
      </div>
      <div class="button-wrapper--right" v-on:click="nextStep" v-if="oldAnswer">
        <button class="next-button">
          <span class="button-span">{{ $t('DilemmaComponent.ButtonNextText') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DilemmaComponent',
  props: {
    test: {},
    question: {},
    oldAnswer: {},
    questionStep: 1,
  },
  data() {
    return {
      selectedOption: 0,
      loadingNextDilemma: false,
      answer: {},
    };
  },
  created() {
    if (this.oldAnswer === undefined) {
      this.selectedOption = 0;
    } else if (this.oldAnswer.selectedOption) {
      this.selectedOption = this.oldAnswer.selectedOption;
    } else {
      this.selectedOption = 0;
    }

    this.loadingNextDilemma = true;
    setTimeout(() => {
      this.loadingNextDilemma = false;
    }, 1500);
  },
  methods: {
    nextStep() {
      if (this.selectedOption !== 0) {
        this.setAnswerObject();
        this.$emit('saveAnswer', this.answer);
        this.$emit('nextPage');
        this.selectedOption = 0;
      }
    },
    previousStep() {
      this.$emit('previousPage');
    },
    changeSelectedOption(inputOption) {
      this.selectedOption = inputOption;
      setTimeout(() => {
        this.nextStep();
      }, 1000);
    },
    setAnswerObject() {
      this.answer.questionId = this.question.id;
      this.answer.questionStep = this.questionStep;
      this.answer.selectedOption = this.selectedOption;
      this.answer.points = 2;
      switch (this.selectedOption) {
        case 1:
          this.answer.archetype = this.question.leftArchetype.archetype;
          break;
        case 2:
          this.answer.archetype = this.question.rightArchetype.archetype;
          break;
      }
      return this.answer;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/app.scss";

.title-div-small {
  margin: 0;
  width: 200%;
  display: flex;
  justify-content: center;
}

.text-div {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.dilemma-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;

  @media (min-width: $vw-tablet) {
    width: 300px;
  }

  @media (min-width: $vw-desktop) {
    width: 400px;
  }


  .info-div {
    @extend .body-small-info;
  }

  .text-div-questionstep {
    @extend .text-div-smallsquashed;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    span {
      color: $colorWhite
    }
  }

  //Everything with .questionstep-input-slider has to do with top slider
  .questionstep-input-slider {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    width: 100%;
    outline: none;
    /*  slider progress trick  */
    overflow: hidden;
    border-radius: 16px;
  }

  /* Track: webkit browsers */
  .questionstep-input-slider::-webkit-slider-runnable-track {
    height: 15px;
    background: $colorWhite;
    border-radius: 16px;
  }

  /* Track: Mozilla Firefox */
  .questionstep-input-slider::-moz-range-track {
    height: 15px;
    background: $colorWhite;
    border-radius: 16px;
  }

  /* Thumb: webkit */
  .questionstep-input-slider::-webkit-slider-thumb {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    height: 15px;
    width: 15px;
    background-color: $colorPink;
    border-radius: 50%;
    border: 2px solid $colorPink;
    /*  slider progress trick  */
    box-shadow: -407px 0 0 400px $colorPink;
  }

  /* Thumb: Firefox */
  .questionstep-input-slider::-moz-range-thumb {
    height: 15px;
    width: 15px;
    background-color: $colorPink;
    border-radius: 50%;
    border: 1px solid $colorPink;
    /*  slider progress trick  */
    box-shadow: -407px 0 0 400px $colorPink;
  }

  .loading-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 200px;
    margin-top: 100px;
    color: $colorGreen;

    .loading-gif {
      width: 100px;
      height: 100px;
    }
  }

  .comparison-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    .archetype-div {
      @extend .text-div-small;
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-bottom: 0;

      width: 150px;
      font-size: 28px;
      line-height: 30px;

      @media (min-width: $vw-desktop) {
        width: 200px;
        font-size: 34px;
        line-height: 40px;
      }

      &:first-child {
        margin-right: 0.5rem;
      }

      img {
        border-radius: 10px;
        max-width: 40vw;
      }

      .archetype-img-option {
        border: 2px solid $colorBlack;
        background: $colorBlack;
        padding-top: 5px;
        cursor: pointer;
      }

      .archetype-img-selected {
        border: 2px solid $colorGreen;
        background: $colorBlack;
        padding-top: 5px;
        cursor: pointer;
      }

      .dilemmaname-span {
        width: 100%;
        margin-top: 0.5rem;
        text-align: left;
        color: $colorWhite;
        cursor: pointer;
      }

      .dilemmaname-span-selected {
        width: 100%;
        margin-top: 0.5rem;
        text-align: left;
        color: $colorGreen;
        cursor: pointer;
      }

      .dilemmadescription-span {
        @extend .body-small-info;
        margin-top: 1rem;
        color: $colorWhite;
        text-align: left;
        width: 140px;
      }
    }

    .other-div {
      @extend .heading3;
      text-align: center;
      position: absolute;
      border-radius: 5px;
      background-color: $colorBlack;
      color: $colorWhite;
      width: 3.5rem;
      height: 3.5rem;
      line-height: 3.5rem;
      z-index: 1;
      margin-top: 7.5rem;

      @media (min-width: $vw-desktop) {
        width: 4rem;
        height: 4rem;
        line-height: 4rem;
        margin-top: 10rem;
      }
    }
  }

  .button-div {
    display: flex;
    flex-direction: row;
    width: 100%;

    @media (min-width: $vw-tablet) {
      margin-top: 3rem;
      margin-bottom: 8rem;
    }

    .button-wrapper--left {
      margin-right: 0.5%;
      width: 49%;

      .previous-button {
        @extend .button-white--left;
        height: 3rem;
        padding: 1rem;
        min-width: 100%;
      }
    }

    .button-wrapper--right {
      margin-left: 0.5%;
      width: 49%;

      .next-button {
        @extend .button-white--right;
        height: 3rem;
        padding: 1rem;
        min-width: 100%;
      }
    }
  }
}
</style>
