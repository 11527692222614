import ApolloClient from '@/js/graphql/ApolloClient';
import { createAccount, loginUser } from '@/js/graphql/mutations';
import { getUsers } from '@/js/graphql/queries';
import { useUserStore } from '@/js/store/userStore';

class AccountService {
    static async login(email, password) {
        const response = await ApolloClient.defaultClient.mutate({
            mutation: loginUser,
            variables: {
                'input': {
                    'email': email,
                    'password': password,
                },
            },
        });

        return response.data;
    }

    static async logout() {
        localStorage.removeItem('jwt-token');
        window.location.reload();
    }

    static async isUserAdmin() {
        const userStore = useUserStore();

        return await userStore.isAdmin()
    }

    static async getAccounts() {
        const response = await ApolloClient.defaultClient.query({
            query: getUsers,
        });

        const users = [];
        response.data.getUsers.users.forEach(user => users.push(user));

        return users;
    }

    static async createAccount(account) {
        if ((account.email === '' || null) || (account.password === '' || null) || (account.companyName === '' || null)) {
            return false;
        }

        const response = await ApolloClient.defaultClient.mutate({
            mutation: createAccount,
            variables: {
                'input': {
                    'email': account.email,
                    'password': account.password,
                    'companyName': account.companyName,
                    'admin': account.admin,
                },
            },
        });

        return response.data;
    }
}

export default AccountService;
