<template>
  <div>
    <div class="title-div-medium">
      <b>
        <em class="title-em-pink">{{ $t('ThankYouComponent.TitlePartOne') }}</em>
        {{ $t('ThankYouComponent.TitlePartTwo') }}
      </b>
    </div>
    <div class="content-div" v-if="!hasSubmitted && test.rewardActive">
      <div class="text-div-small">
        {{ $t('ThankYouComponent.IntroPartOne') }}
      </div>
      <div class="text-div-small-nomargin">
        {{ $t('ThankYouComponent.IntroPartTwo') }}*
      </div>
      <div class="smallnote-div">
        * {{ $t('ThankYouComponent.DontWantReward') }}
      </div>
      <div class="text-div-small">
        <div class="flexcolumn-div">
          <input type="email" placeholder="Email" class="rewardemail-input" v-model="rewardEmail">
          <em class="text-em-black">
            {{ this.errorMessage }}
          </em>
        </div>
        <input type="checkbox" class="privacy-checkbox" v-model="agreeWithTerms">
        {{ $t('ThankYouComponent.PrivacyConfirmationText') }}
      </div>
    </div>
    <div class="button-div" v-if="!hasSubmitted && test.rewardActive">
      <div class="button-wrapper--right" v-on:click="submitRewardEmail" :disabled="hasSubmitted">
        <button class="next-button">
          <span class="button-span">{{ $t('ThankYouComponent.Participate') }}</span>
        </button>
      </div>
    </div>
    <div class="content-div" v-if="hasSubmitted">
      <div class="text-div-small">
        {{ $t('ThankYouComponent.EmailSend') }}
      </div>
      <div class="text-div-small-nomargin">
        {{ $t('ThankYouComponent.ClosePage') }}
      </div>
    </div>
    <div class="content-div" v-if="!test.rewardActive">
      <div class="text-div-small-nomargin">
        {{ $t('ThankYouComponent.ClosePage') }}
      </div>
    </div>
  </div>
</template>

<script>
import RewardEmailService from '@/js/services/unSecureServices/RewardEmailService';

export default {
  name: 'ThankYouComponent',
  props: {
    test: {},
  },
  data() {
    return {
      errorMessage: '',
      agreeWithTerms: false,
      rewardEmail: '',
      hasSubmitted: false,
    };
  },
  methods: {
    async submitRewardEmail() {
      this.errorMessage = '';
      if (this.rewardEmail !== '' && this.validateEmail(this.rewardEmail)) {
        if (this.agreeWithTerms) {
          let result = await RewardEmailService.sendRewardEmail(this.rewardEmail, this.test.id);
          if (result.status) {
            this.hasSubmitted = true;
          }
        } else {
          this.errorMessage = this.$t('ThankYouComponent.InvalidCheckbox');
        }
      } else {
        this.errorMessage = this.$t('ThankYouComponent.InvalidEmail');
      }
    },
    validateEmail(email) {
      return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/app.scss";

.text-div-smallsquashed {
  margin-top: 2rem;
  margin-bottom: 0;
}

.content-div {
  background-color: $colorPink;
  margin: 3rem 0;
  padding: 25px 15px;
  border-radius: 25px;

  .text-div-small-nomargin {
    @extend .text-div-small;
    margin-bottom: 0;
  }

  .smallnote-div {
    font-size: 14px;
    color: $colorWhite;
    margin-bottom: 1.5rem;
  }

  .flexcolumn-div {
    display: flex;
    flex-direction: column;
  }

  .button-div {
    display: flex;
    justify-content: center;
    width: 100%;

    .next-button {
      @extend .button-white--right;
      padding: 1rem;
      height: 3rem;
    }
  }

  .rewardemail-input {
    height: 2.5rem;
    width: 93%;
    background-color: $colorPink;
    border: 1px solid $colorWhite;
    border-radius: 5px;
    padding-left: 1rem;
    color: $colorWhite;
    font-size: 16px;
    line-height: 32px;
    font-weight: 500;

    &:focus {
      outline: none;
    }
  }

  .privacy-checkbox {
    accent-color: $colorBlack;
  }
}
</style>
