import { ApolloClient, createHttpLink, InMemoryCache, from } from '@apollo/client/core';
import { createApolloProvider } from '@vue/apollo-option';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
    uri: '/graphql/',

});

const cache = new InMemoryCache();

const apolloClient = new ApolloClient({
    cache,
    uri: '/graphql/',
});

const apolloProvider = createApolloProvider({
    defaultClient: apolloClient,
});

export default apolloProvider;
