import '@/styles/app.scss';

import { createApp } from 'vue';
import { RouterLink } from 'vue-router';
import router from '@/js/routes/router';
import apolloProvider from '@/js/graphql/ApolloClient';
import unsecureApolloProvider from '@/js/graphql/UnsecureApolloClient';
import App from '@/js/components/App.vue';
import { plugin, defaultConfig } from '@formkit/vue';
import { nl } from '@formkit/i18n';
import pinia from '@/js/store/pinia';
import { createI18n } from "vue-i18n";

//Translations
import nlLocale from "@/locales/nl.json";
import enLocale from "@/locales/en.json";

const app = createApp(App);

app.use(router);
app.component('router-link', RouterLink);

const i18n = createI18n({
    locale: "nlLocale",
    fallbackLocale: "nlLocale",
    messages: {nlLocale, enLocale},
});
app.use(i18n);

app.use(pinia);

app.use(apolloProvider);
app.use(unsecureApolloProvider);

app.use(plugin, defaultConfig({
    locales: { nl },
    locale: 'nl',
}));

app.mount('#app');
