<template>
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=2">
    <div id="app-admin" v-if="!$route.meta.hideNavBars && $route.meta.adminPage">
        <div class="leftSideBar">
            <NavbarComponent />
        </div>
        <div class="rightSide">
<!--            <TopbarComponent />-->
            <router-view />
        </div>
    </div>
    <div id="app-admin-login" v-if=" $route.meta.hideNavBars && $route.meta.adminPage">
        <router-view />
    </div>
    <div id="app" v-if="!$route.meta.adminPage && $route.meta.hideNavBars">
        <router-view />
    </div>
</template>

<script>
import NavbarComponent from '@/js/components/elements/NavbarComponent.vue';
import TopbarComponent from '@/js/components/elements/TopbarComponent.vue';

export default {
    name: 'App',
    components: {
        NavbarComponent,
        TopbarComponent,
    },
};
</script>

<style scoped lang="scss">
@import "@/styles/app.scss";

#app {
  display: flex;
  flex-direction: row;
  background-image: url('~@/images/background/bg_mobiel.png');
  justify-content: center;
  overflow: auto;
  overflow-x: hidden;


  @media (min-width: $vw-tablet) {
    background-image: url('~@/images/background/bg_desktop.png');
  }

  @media (min-width: $vw-desktop) {
    background-image: url('~@/images/background/bg_desktop.png');
  }
}


#app-admin {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#app-admin-login {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-image: url('~@/images/background/bg_desktop.png');

}

.leftSideBar {
  width: 15vw;
  min-width: 200px;
  height: 100vh;
}

.rightSide {
  display: flex;
  flex-direction: column;
  width: 85vw;
  max-height: 100vh;
  overflow-y: scroll;
}
</style>
