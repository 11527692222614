import ApolloClient from '@/js/graphql/ApolloClient';
import { getCompanyData, getCompanyNames } from '@/js/graphql/queries';
import { addCompany } from '@/js/graphql/mutations';

class CompanyService {
    static async getCompanyNames() {
        const response = await ApolloClient.defaultClient.query({
            query: getCompanyNames,
        });

        const companies = []
        response.data.getCompanies.companies.forEach(company => companies.push(company.name))

        return companies;
    }

    static async getCompanies() {
        const response = await ApolloClient.defaultClient.query({
            query: getCompanyData,
        })

        const companies = []
        response.data.getCompanies.companies.forEach(company => companies.push(company))

        return companies;
    }

    static async addCompany(name) {
        const response = await ApolloClient.defaultClient.mutate({
            mutation: addCompany,
            variables: {
                'name': name,
            },
        });

        return response.data;
    }
}

export default CompanyService;
