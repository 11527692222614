<template>
  <div>
    <div class="title-div-medium">
      <b>
        {{ $t('RankingExplanationComponent.TitlePartOne') }}
        <em class="title-em-pink">{{ $t('RankingExplanationComponent.TitlePartTwo') }}</em>
      </b>
    </div>

    <div class="text-div-smallsquashed">
      {{ $t('RankingExplanationComponent.IntroPartOne') + test.company.name }}.<br><br>
      {{ $t('RankingExplanationComponent.IntroPartTwo') }}<br><br>
      <em class="text-em-black">{{ $t('RankingExplanationComponent.IntroPartThree') }}</em>
    </div>
    <div class="text-div">
      <div class="comparison-div">
        <img class="explanation-img" src="@/images/explanationRankingImage.png"/>
      </div>
    </div>
    <div class="button-div">
      <div class="button-wrapper--left" v-on:click="previousStep">
        <button class="previous-button">
          <span class="button-span">{{ $t('RankingExplanationComponent.ButtonPreviousText') }}</span>
        </button>
      </div>
      <div class="button-wrapper--right" v-on:click="nextStep">
        <button class="next-button">
          <span class="button-span">{{ $t('RankingExplanationComponent.ButtonNextText') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExplanationComponent',
  props: {
    test: {},
  },
  methods: {
    nextStep() {
      this.$emit('nextPage');
    },
    previousStep() {
      this.$emit('previousPage');
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/app.scss";

.content-div {

  .text-div-smallsquashed {
    background: $colorPink;
    padding: 10px 20px;
    border-radius: 25px;
  }

  .comparison-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .explanation-img {
      width: 300px;
      pointer-events: none; //To prevent search-icon thing on edge browser that popped up

      @media (min-width: $vw-tablet) {
        width: 50%;
      }

      @media (min-width: $vw-desktop) {
        width: 50%;
      }
    }
  }

  .button-div {
    display: flex;
    justify-content: center;
    margin: auto;
    width: 75%;

    @media (min-width: $vw-tablet) {
      margin-top: 4rem;
      margin-bottom: 8rem;
      width: 50%;
    }

    .button-wrapper--left {
      margin-right: 0.5%;
      width: 49%;

      .previous-button {
        @extend .button-white--left;
        height: 3rem;
        padding: 1rem;
        min-width: 100%;
      }
    }

    .button-wrapper--right {
      margin-left: 0.5%;
      width: 49%;

      .next-button {
        @extend .button-white--right;
        height: 3rem;
        padding: 1rem;
        min-width: 100%;
      }
    }
  }
}
</style>
