<template>
    <div class="topbar-div">
        <h1>{{ $t('AccountsView.Title') }}</h1>
        <div class="topbarbuttons-div">
            <CreateAccountModal @addAccountToList="addNewAccountToList" v-if="userIsAdmin"/>
        </div>
    </div>
    <div class="accountcontent-div">
        <div class="accountcollection-div">
            <span class="tabletitle-span">{{ $t('AccountsView.TableTitle') }}</span>
            <span class="tablecount-span">{{ accounts.length }} {{ $t('AccountsView.total') }}</span>
            <table class="account-table" v-if="accounts.length >= 1">
                <tr>
                    <th></th>
                    <th>{{ $t('AccountsView.Email') }}</th>
                    <th>{{ $t('AccountsView.Company') }}</th>
                    <th>{{ $t('AccountsView.Role') }}</th>
                    <th>{{ $t('AccountsView.LastLoggedIn') }}</th>
                    <th>{{ $t('AccountsView.CreatedAt') }}</th>
                    <th>{{ $t('AccountsView.Password') }}</th>
                </tr>
                <tr v-for="account in accounts" :key="account.id">
                    <td>
                        <div class="checkbox-div">
                            <input class="tablecheckbox-input" type="checkbox">
                        </div>
                    </td>
                    <td>{{ account.email }}</td>
                    <td>{{ account.company.name }}</td>
                    <td>{{ account.admin ? $t('AccountsView.Admin') : $t('AccountsView.User') }}</td>
                    <td>{{ account.lastLoggedIn ? account.lastLoggedIn.slice(0, 10) : $t('AccountsView.NoLoginYet') }}</td>
                    <td>{{ account.createdAt.slice(0, 10) }}</td>
                    <td v-if="userIsAdmin">
                        <button class="intable-button" v-on:click="resetPassword(account)">
                            {{ $t('AccountsView.ForgotPasswordButton') }}
                        </button>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import { useUserStore } from '@/js/store/userStore';
import CreateAccountModal from '@/js/components/modals/CreateAccountModal.vue';
import accountService from '@/js/services/secureServices/AccountService';

export default {
    name: 'AccountView',
    components: { CreateAccountModal },
    data() {
        return {
            userStore: useUserStore(),
            accounts: [],
            userIsAdmin: false,
        };
    },
    async created() {
        this.accounts = await accountService.getAccounts();
        this.userIsAdmin = await accountService.isUserAdmin();
    },
    methods: {
        addNewAccountToList(account) {
            this.accounts.unshift(account)
        },
        resetPassword(account) {
            alert("Not yet implemented");
        }
    }
};
</script>

<style scoped lang="scss">
@import "@/styles/app.scss";

.topbar-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: $colorWhite;
  min-height: 107px;

  h1 {
    width: 80%;
    margin-left: 3rem;
    color: $colorBlack;
  }

  .topbarbuttons-div {
    width: 20%;

    button {
      @extend .button-black;
      width: 208px;
      height: 64px;
      margin: 24px 48px 19px 0;
      float: right;
    }
  }
}

.accountcontent-div {
  display: flex;
  flex-direction: column;
  margin: 20px;

  .accountcollection-div {
    display: flex;
    flex-direction: column;
    background-color: $colorWhite;
    padding: 72px 48px 48px 48px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    .tabletitle-span {
      @extend .table-title;
      color: $colorBlack;
      margin-bottom: 16px;
    }

    .tablecount-span {
      @extend .table-count;
    }

    .account-table {
      border-collapse: collapse;
      background-color: $colorWhite;
      width: 100%;
      margin-top: 16px;

      th {
        @extend .table-header;
        border-top: 1px solid $colorMistyGray;
        border-bottom: 1px solid $colorMistyGray;
        color: $colorSilverGray;
        text-align: left;
        padding: 6px;
      }

      td {
        @extend .table-row;
        vertical-align: bottom;
        text-align: left;
        padding: 6px;
        word-break: break-word;

        .checkbox-div {
          display: flex;
          justify-content: center;
          align-items: center;

          .tablecheckbox-input {
            height: 30px;
            width: 30px;
            border: 1px solid $colorSilverGray;
            border-radius: 5px;
            accent-color: $colorBlack;
            margin: 10px;
          }
        }

        .clickable-span {
          text-decoration: underline;
          cursor: pointer;

          .copyicon-img {
            height: 22px;
            width: 19px;
          }
        }

        .intable-button {
          @extend .button-intable;
          padding: 16px;
        }
      }
    }
  }
}
</style>
