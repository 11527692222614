import { createRouter, createWebHistory } from 'vue-router';

import CompanyView from '@/js/components/views/CompanyView.vue';
import TestView from '@/js/components//views/TestView.vue';
import QuestionView from '@/js/components//views/QuestionView.vue';
import AccountView from '@/js/components//views/AccountView.vue';
import LoginView from '@/js/components//views/LoginView.vue';
import RespondentView from '@/js/components/views/RespondentView.vue';
import LandingPageView from "@/js/components/views/LandingPageView.vue";

const routes = [
    {
        path: '/',
        name: 'LandingPage',
        component: LandingPageView,
        meta: {
            hideNavBars: true,
            displayName: 'Home',
        }
    },
    {
        path: '/admin/login',
        name: 'adminLogin',
        component: LoginView,
        meta: {
            hideNavBars: true,
            displayName: 'Login',
            adminPage: true,
        }
    },
    {
        path: '/admin/test',
        name: 'adminTest',
        component: TestView,
        meta: {
            displayName: 'Tests',
            adminPage: true,
        }
    },
    {
        path: '/admin/company',
        name: 'adminCompany',
        component: CompanyView,
        meta: {
            displayName: 'Companies',
            adminPage: true,
        }
    },
    {
        path: '/admin/question',
        name: 'adminQuestion',
        component: QuestionView,
        meta: {
            displayName: 'Questions',
            adminPage: true,
        }
    },
    {
        path: '/admin/account',
        name: 'adminAccount',
        component: AccountView,
        meta: {
            displayName: 'Accounts',
            adminPage: true,
        }
    },
    {
        path: '/klant/:companyName/:testName',
        name: 'test',
        component: RespondentView,
        meta: {
            hideNavBars: true,
            displayName: 'Test for users to fill in',
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});


const isLoggedIn = () => {
    return localStorage.getItem('jwt-token');
}

const protectedRoutes = [
    "adminTest",
    "adminCompany",
    "adminQuestion",
    "adminAccount",
]

router.beforeEach((to, from, next) => {
    const isProtected = protectedRoutes.includes(to.name)
    if(isProtected && !isLoggedIn()) {
        next({
            name: 'adminLogin'
        })
    }else next()
})
export default router;
