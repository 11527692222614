import ApolloClient from '@/js/graphql/ApolloClient';
import { getRewardEmail } from '@/js/graphql/queries';

class RewardEmailService {
    static async getRewardEmail(testId) {
        const response = await ApolloClient.defaultClient.query({
            query: getRewardEmail,
            variables: {
                'testId': testId,
            },
        });

        return response.data.getRandomRewardEmail;
    }
}

export default RewardEmailService;
