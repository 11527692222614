<template>
  <div>
    <div class="title-div-medium">
      <b>
        {{ $t('ExplanationComponent.TitlePartOne') }}
        <em class="title-em-pink">{{ $t('ExplanationComponent.TitlePartTwo') }}</em>
      </b>
    </div>

    <div class="text-div-smallsquashed">
      {{ $t('ExplanationComponent.IntroPartOne') }}<br><br>
      {{ $t('ExplanationComponent.IntroPartTwo') }}<br><br>
      {{ $t('ExplanationComponent.IntroPartThree') }}<br><br>
      <em class="text-em-black">{{ $t('ExplanationComponent.IntroPartFour') }}</em>
    </div>
    <div class="text-div">
      <div class="comparison-div">
        <img class="explanation-img" src="@/images/explanationImage.png"/>
      </div>
      <div class="areyouready-div">
        <em class="text-em-pink">{{ $t('ExplanationComponent.AreYouReady') }}</em>
      </div>
    </div>
    <div class="button-div">
      <div class="button-wrapper--right" v-on:click="nextStep">
        <button class="next-button">
          <span class="button-span">{{ $t('ExplanationComponent.ButtonNextText') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExplanationComponent',
  props: {
    test: {},
  },
  data() {
    return {
      selectedOption: 4,
    };
  },
  methods: {
    nextStep() {
      this.$emit('nextPage');
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/app.scss";

.content-div {

  .text-div-smallsquashed {
    background: $colorPink;
    padding: 10px 20px;
    border-radius: 25px;
  }

  .comparison-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .explanation-img {
      pointer-events: none; //To prevent search-icon thing on edge that popped up

      @media (min-width: $vw-tablet) {
        width: 50%;
      }

      @media (min-width: $vw-desktop) {
        width: 50%;
      }
    }
  }

  .areyouready-div {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
  }

  .button-div {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 1px;

    @media (min-width: $vw-tablet) {
      margin-top: 4rem;
      margin-bottom: 8rem;
    }

    .next-button {
      @extend .button-white--right;
      padding: 1rem;
      height: 3rem;
    }
  }

  .explanation-img {
    width: 100%;
  }
}
</style>
