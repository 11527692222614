<template>
    <div class="topbar-div">
        <h1>{{ $t('QuestionView.Title') }}</h1>
        <div class="topbarbuttons-div">
            <CreateQuestionModal v-if="userIsAdmin"/>
        </div>
    </div>
    <div class="questioncontent-div">

    </div>
</template>

<script>
import CreateQuestionModal from '@/js/components/modals/CreateQuestionModal.vue';
import accountService from '@/js/services/secureServices/AccountService';

export default {
    name: 'QuestionView',
    components: { CreateQuestionModal },
    data() {
        return {
            userIsAdmin: false,
        };
    },
    async created() {
        this.userIsAdmin = await accountService.isUserAdmin();
    },
};
</script>

<style scoped lang="scss">
@import "@/styles/app.scss";

.topbar-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: $colorWhite;
  min-height: 107px;

  h1 {
    width: 80%;
    margin-left: 3rem;
    color: $colorBlack;
  }

  .topbarbuttons-div {
    width: 20%;

    button {
      @extend .button-black;
      width: 208px;
      height: 64px;
      margin: 24px 48px 19px 0;
      float: right;
    }
  }
}

.questioncontent-div {
  display: flex;
  flex-direction: column;
  margin: 20px;
}
</style>
