<template>
    <div class="topbar-div">
        <h1>{{ $t('CompanyView.Title') }}</h1>
        <div class="topbarbuttons-div">
            <CreateCompanyModal @addCompanyToLists="addNewCompanyToList" v-if="userIsAdmin"/>
        </div>
    </div>
    <div class="companycontent-div">
        <div class="companycollection-div">
            <span class="tabletitle-span">{{ $t('CompanyView.TableTitle') }}</span>
            <span class="tablecount-span">{{ companies.length }} {{ $t('CompanyView.total') }}</span>
            <table class="company-table" v-if="companies.length >= 1">
                <tr>
                    <th></th>
                    <th>{{ $t('CompanyView.Company') }}</th>
                    <th>{{ $t('CompanyView.Archetype') }}</th>
                    <th>{{ $t('CompanyView.TotalTests') }}</th>
                    <th>{{ $t('CompanyView.TotalAccounts') }}</th>
                    <th>{{ $t('CompanyView.LastEdited') }}</th>
                </tr>
                <tr v-for="company in companies" :key="company.id">
                    <td>
                        <div class="checkbox-div">
                            <input class="tablecheckbox-input" type="checkbox">
                        </div>
                    </td>
                    <td>{{ company.name }}</td>
                    <td>{{ company.archetype }}</td>
                    <td>{{ company.tests.length }}</td>
                    <td>{{ company.users.length }}</td>
                    <td>{{ company.updatedAt || $t('CompanyView.UnknownUpdatedAt') }}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import CompanyService from '@/js/services/secureServices/CompanyService';
import CreateCompanyModal from '@/js/components/modals/CreateCompanyModal';
import accountService from '@/js/services/secureServices/AccountService';

export default {
    name: 'CompanyView',
    components: {
        CreateCompanyModal,
    },
    data() {
        return {
            companies: [],
            userIsAdmin: false,
        };
    },
    async created() {
        this.companies = await CompanyService.getCompanies();
        this.userIsAdmin = await accountService.isUserAdmin();
    },
    methods: {
        addNewCompanyToList(company) {
            this.companies.push(company)
        }
    }
};
</script>

<style scoped lang="scss">
@import "@/styles/app.scss";

.topbar-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: $colorWhite;
  min-height: 107px;

  h1 {
    width: 80%;
    margin-left: 3rem;
    color: $colorBlack;
  }

  .topbarbuttons-div {
    width: 20%;

    button {
      @extend .button-black;
      width: 208px;
      height: 64px;
      margin: 24px 48px 19px 0;
      float: right;
    }
  }
}

.companycontent-div {
  display: flex;
  flex-direction: column;
  margin: 20px;


  .companycollection-div {
    display: flex;
    flex-direction: column;
    background-color: $colorWhite;
    padding: 72px 48px 48px 48px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    .tabletitle-span {
      @extend .table-title;
      color: $colorBlack;
      margin-bottom: 16px;
    }

    .tablecount-span {
      @extend .table-count;
    }

    .company-table {
      border-collapse: collapse;
      background-color: $colorWhite;
      width: 100%;
      margin-top: 16px;

      th {
        @extend .table-header;
        border-top: 1px solid $colorMistyGray;
        border-bottom: 1px solid $colorMistyGray;
        color: $colorSilverGray;
        text-align: left;
        padding: 6px;
      }

      td {
        @extend .table-row;
        text-align: left;
        padding: 6px;
        word-break: break-word;

        .checkbox-div {
          display: flex;
          justify-content: center;
          align-items: center;

          .tablecheckbox-input {
            height: 30px;
            width: 30px;
            border: 1px solid $colorSilverGray;
            border-radius: 5px;
            accent-color: $colorBlack;
            margin: 10px;
          }
        }

        .clickable-span {
          text-decoration: underline;
          cursor: pointer;

          .copyicon-img {
            height: 22px;
            width: 19px;
          }
        }

        .intable-button {
          @extend .button-intable;
          padding: 16px;
        }
      }
    }
  }
}
</style>
