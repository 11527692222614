import ApolloClient from '@/js/graphql/UnsecureApolloClient';
import { addScreenOutAnswers } from '@/js/graphql/mutations';

class ScreenOutAnswerService {
    static async addScreenOutAnswers(screenOutAnswers, respondentId, suitableRespondent) {
        const response = await ApolloClient.defaultClient.mutate({
            mutation: addScreenOutAnswers,
            variables: {
                'respondentId': respondentId,
                'suitable': suitableRespondent,
                'input': screenOutAnswers
            },
        });

        return response.data.addScreenOutAnswers;
    }
}

export default ScreenOutAnswerService;
